var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { ModalConfirmForm } from 'app/components/Modal';
import { SketchPicker } from 'react-color';
import { FormData } from 'app/components/Form';
import resources from 'app/store/resources';
import styles from 'app/styles/vars.js';
import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { createDepartmentJobs, deleteDepartmentJobs } from 'app/store/reducers/departmentJobs';
import { useDispatch } from 'react-redux';
import { createDepartment, fetchOneDepartment, updateDepartment } from 'app/store/reducers/departments';
export function ModalEditDepartment(props) {
    var _a;
    const { department, onSave, onChange } = props, modalProps = __rest(props, ["department", "onSave", "onChange"]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        resources.jobs.fetchAll({ params: { queries: { page_size: 1000 } } }).then((res) => {
            setJobs(res.resources);
            setIsLoading(false);
        });
    }, []);
    function save(data) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (department && (department === null || department === void 0 ? void 0 : department.id)) {
                // UPDATE DEPARTMENT
                const newDepartment = Object.assign(Object.assign({}, department), data);
                if (onSave)
                    return onSave(data);
                // departmentJobs relations
                const currentJobsIds = department === null || department === void 0 ? void 0 : department.jobs;
                const newJobsIds = data.jobs.map((job) => job.value);
                const toRemoveIds = (currentJobsIds === null || currentJobsIds === void 0 ? void 0 : currentJobsIds.filter((job) => !newJobsIds.includes(job))) || [];
                const toAddIds = newJobsIds.filter((job) => !(currentJobsIds === null || currentJobsIds === void 0 ? void 0 : currentJobsIds.includes(job)));
                const toRemoveRelationsIds = toRemoveIds.map((idToRemove) => { var _a; return (_a = department.rel_departmentJobsInst) === null || _a === void 0 ? void 0 : _a.find((relation) => relation.job === idToRemove).id; });
                const toAddJobs = toAddIds.map((jobId) => ({
                    department: department === null || department === void 0 ? void 0 : department.id,
                    job: jobId,
                }));
                yield Promise.all([
                    toRemoveIds.length > 0 && dispatch(deleteDepartmentJobs(toRemoveRelationsIds)),
                    toAddIds.length > 0 && dispatch(createDepartmentJobs(toAddJobs)),
                ]);
                // department data
                const res = yield dispatch(updateDepartment(newDepartment));
                return res;
            }
            else {
                // CREATE DEPARTMENT
                if (onSave)
                    return onSave(data);
                // department data
                const res = yield dispatch(createDepartment(data));
                // departmentJobs relations
                const jobsIds = data.jobs.map((job) => job.value);
                const toAddJobs = jobsIds.map((jobId) => {
                    var _a;
                    return ({
                        // @ts-ignore
                        department: (_a = res.payload) === null || _a === void 0 ? void 0 : _a.id,
                        job: jobId,
                    });
                });
                yield dispatch(createDepartmentJobs(toAddJobs));
                // @ts-ignore
                yield dispatch(fetchOneDepartment((_a = res.payload) === null || _a === void 0 ? void 0 : _a.id));
                return res;
            }
        });
    }
    const colorPicker = (data, setData) => {
        return (_jsx(SketchPicker, { onChangeComplete: (color) => {
                setData(Object.assign(Object.assign({}, data), { color: color.hex }));
            }, disableAlpha: true, presetColors: styles.randomColors, color: data.color }));
    };
    return (_jsx(ModalConfirmForm, Object.assign({ title: department ? `Edit department ${department.name || ''}` : 'Create a new department', draggable: true }, modalProps, { children: _jsx(FormData, { onSave: onSave || save, flashNotifSuccessLabel: `Department ${department ? 'edited' : 'created'}`, defaultData: Object.assign(Object.assign({}, department), { jobs: ((_a = department === null || department === void 0 ? void 0 : department.jobsInst) === null || _a === void 0 ? void 0 : _a.map((job) => ({
                    value: job.id,
                    label: job.name,
                    data: job,
                }))) || [] }), properties: [
                {
                    label: 'Department name',
                    key: 'name',
                    type: 'string',
                    elementProps: {
                        isRequired: true,
                    },
                },
                {
                    label: 'Quotation reference',
                    key: 'quoteReference',
                    type: 'string',
                },
                {
                    key: 'color',
                    label: 'Color',
                    type: 'custom',
                    elementProps: {
                        isRequired: true,
                    },
                    element: colorPicker,
                },
                {
                    label: 'Jobs',
                    key: 'jobs',
                    type: 'muiautocomplete',
                    elementProps: (data, setData) => {
                        return {
                            dataCy: 'jobsTitles',
                            value: data.jobs,
                            onChange: (option) => {
                                setData(Object.assign(Object.assign({}, data), { jobs: option }));
                            },
                            options: sortBy(jobs, ['jobTitle']).map((job) => ({
                                label: job.name,
                                value: job.id,
                                data: job,
                            })),
                            multiple: true,
                            isRequired: true,
                            style: { width: '100%' },
                        };
                    },
                },
            ] }) })));
}
