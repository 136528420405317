/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import type { TrackingSchemaColumn } from 'app/core/types'
import type { ModalProps } from 'app/components/Modal/MUIModal.jsx'
import type { AttributeTypes } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/attributeAssets'
import ModalConfirmForm from 'app/components/Modal/ModalConfirmForm'
import FormData from 'app/components/Form/FormData/FormData'
import isBoolean from 'lodash/isBoolean'
import { Field } from 'app/components/Form/FormData/getInput.tsx'
import { ChoiceTypeInput } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/ChoiceTypeInput.jsx'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { attributeTypes, getAttrType } from './AttributesEditor/attributeAssets'

type ModalEditTrackingSchemaColumnProps = {|
  ...$Shape<$Diff<ModalProps, { children: any }>>,
  column: TrackingSchemaColumn<>,
  onSave: (data: $Shape<TrackingSchemaColumn<>>) => Promise<any>,
|}

export function ModalEditTrackingSchemaColumn(props: ModalEditTrackingSchemaColumnProps): React$Node {
  const { column, onSave, ...rest } = props
  const projectId = useMemo(() => getProjectIdFromURL() || '', [])

  const editorTypeColumn = (editorType) => {
    if (editorType === 'choice') return column.attrType
    if (editorType === 'str') return column.attrType
    if (editorType === 'int') return column.attrType
    if (editorType === 'txt') return column.attrType
    return column.editorType
  }

  const attributeParams = column.editorParams ? Object.keys(column.editorParams) : []

  const [attributeType, setAttributeType] = useState<AttributeTypes>({
    value: column.attrType || '',
    label: column.attrType || '',
    params: attributeParams,
  })

  useEffect(() => {
    const nextAttributeType: AttributeTypes | void = attributeTypes.find(
      (attrType) => attrType.value === editorTypeColumn(column.editorType),
    )
    if (column.editorType) {
      if (nextAttributeType) setAttributeType(nextAttributeType)
    }
  }, [column.editorType])

  const params = attributeType?.params || []

  const _editorParams = params
    .filter((param) => {
      if (param === 'slider') return false
      return true
    })
    .map((param) => {
      switch (param) {
        case 'choice':
          return {
            key: 'choice',
            label: 'List of choices',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <ChoiceTypeInput type={data?.attrType?.value} data={data} setData={setData} />
            ),
            elementProps: {},
          }
        case 'min':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'number',
            },
          }
        case 'max':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'number',
            },
          }
        case 'timeMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'time',
            },
          }
        case 'timeMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'time',
            },
          }
        case 'dateMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'date',
            },
          }
        case 'dateMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'date',
            },
          }
        case 'datetimeMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <Field type="datetime" dataKey="min" data={data} setData={setData} />
            ),
          }
        case 'datetimeMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <Field type="datetime" dataKey="max" data={data} setData={setData} />
            ),
          }
        case 'step':
          return {
            key: 'step',
            label: 'Step',
            type: 'string',
            elementProps: {
              type: 'number',
              placeholder: 'ex: 0.1',
            },
          }
        case 'colored':
          return {
            key: 'colored',
            label: 'Colored',
            type: 'checkbox',
          }
        case 'framerate':
          return {
            key: 'framerate',
            label: 'Framerate',
            type: 'string',
            elementProps: {
              type: 'number',
              placeholder: 'ex: 24',
            },
          }
        default:
          throw new Error('The attribute type does not exist.')
      }
    })

  const attributeTypeOption: Array<Object> = attributeTypes.filter((attrType) => attrType.value !== 'skill')

  const formdataToAttribute: (Object) => { ...EditColumn } = (attribute) => {
    const {
      columnName,
      description,
      fixed,
      fixable,
      hiddenable,
      isVisible,
      hidden,
      readOnly,
      attrType,
      editorType,
      id,
      choice,
      onEditEditorType,
      ..._editorParams
    } = attribute
    const editorParams = _editorParams || {}

    if (choice && Array.isArray(choice) && choice.length > 0) {
      editorParams.choice = choice
    }

    return {
      columnName,
      description,
      fixed,
      fixable,
      hiddenable,
      isVisible,
      hidden,
      readOnly,
      attrType,
      editorType,
      id,
      editorParams: _editorParams,
      project: projectId || undefined,
      onEditEditorType,
    }
  }

  return (
    <ModalConfirmForm title={`Edit column ${column.columnName}`} draggable={false} {...rest}>
      <FormData
        defaultData={{
          columnName: column.columnName,
          description: column.description,
          fixed: column.fixed,
          fixable: isBoolean(column.fixable) ? column.fixable : true,
          hiddenable: isBoolean(column.hiddenable) ? column.hiddenable : true,
          isVisible: isBoolean(column.isVisible) ? column.isVisible : true,
          hidden: column.hidden,
          readOnly: isBoolean(column.readOnly) ? column.readOnly : false,
          attrType: column.attrType ? { label: column.attrType, value: column.attrType } : null,
          editorType: column.editorType ? editorTypeColumn(column.editorType) : null,
          onEditEditorType: column.onEditEditorType || false,
          choice: column?.editorParams?.choice || null,
          min: column?.editorParams?.min ?? null,
          max: column?.editorParams?.max ?? null,
          timeMin: column?.editorParams?.timeMin || null,
          timeMax: column?.editorParams?.timeMax || null,
          dateMin: column?.editorParams?.dateMin || null,
          dateMax: column?.editorParams?.dateMax || null,
          datetimeMin: column?.editorParams?.datetimeMin || null,
          datetimeMax: column?.editorParams?.datetimeMax || null,
          step: column?.editorParams?.step || null,
          colored: column?.editorParams?.colored || null,
          framerate: column?.editorParams?.framerate || null,
        }}
        properties={[
          {
            key: 'columnName',
            label: 'Column name',
            type: 'string',
            elementProps: {
              isRequired: true,
              placeholder: 'ex: Created by',
            },
          },
          {
            key: 'description',
            label: 'Description',
            type: 'textarea',
            elementProps: {
              style: { width: '100%' },
              placeholder: 'Column description (optional)',
            },
          },
          {
            label: 'Hiddenable',
            key: 'hiddenable',
            type: 'checkbox',
          },
          {
            label: 'Visible by default',
            key: 'isVisible',
            type: 'checkbox',
          },
          {
            label: 'Visible only for filters',
            key: 'hidden',
            type: 'checkbox',
          },
          {
            label: 'Pinnable',
            key: 'fixable',
            type: 'checkbox',
          },
          {
            key: 'fixed',
            label: 'Pinned by default',
            type: 'select',
            elementProps: {
              fullWidth: true,
              options: [
                { label: 'none', value: undefined },
                { label: 'left', value: 'left' },
                { label: 'right', value: 'right' },
              ],
            },
          },
          {
            key: 'readOnly',
            label: 'Read only',
            type: 'checkbox',
          },
          {
            key: 'editorType',
            label: 'Change type',
            type: 'select',
            elementProps: (data: Object, setData: Function) => ({
              fullWidth: true,
              options: attributeTypeOption,
              onChange: (value: Object) => {
                setAttributeType(value)
                setData({
                  ...data,
                  attrType: getAttrType(value.value),
                  editorType: value.value,
                  onEditEditorType: true,
                })
              },
            }),
          },
          {
            key: 'onEditEditorType',
            label: 'Edit editor type',
            type: 'checkbox',
            isShow: () => false,
          },
          ..._editorParams,
        ]}
        onSave={(formValues) => onSave(formdataToAttribute({ ...formValues, id: column && column.id }))}
        flashNotifSuccessLabel="Column updated"
      />
    </ModalConfirmForm>
  )
}
