// @flow
import React from 'react'
import { regex } from 'app/libs/helpers/regex'
import { mapValues, map, reduce } from 'lodash'
import { Field } from 'app/components/Form/FormData/getInput.tsx'
import type { AttrType, EditorType, Category, Option, EditorParams } from 'app/core/types'
import { getServerConfig } from 'app/core/utils/getServerConfig.js'
import type { FormDataProperty } from 'app/components/Form/FormData/FormData.jsx'
import { ChoiceTypeInput } from './ChoiceTypeInput.jsx'

export type AttributeTypes = {
  value: AttrType,
  label: string,
  params: Array<$Keys<EditorParams>>,
}

export const CONFIG_ENTRIES = {
  SKILL_LEVELS: 'Skill levels',
}

export const getAttrType = (attrType: AttrType): AttrType => {
  if (attrType === 'rating') return 'integer'
  if (attrType === 'priority') return 'integer'
  if (attrType === 'iban') return 'char'
  if (attrType === 'phone') return 'char'
  if (attrType === 'ssn') return 'char'
  if (attrType === 'timecode') return 'integer'

  return attrType
}

export const getEditorType = ({ choice, slider, attrType }: Object): EditorType => {
  if (choice) return 'choice'
  if (slider) return 'slider'
  if (attrType.value === 'integer') return 'int'
  if (attrType.value === 'char') return 'str'
  if (attrType.value === 'ip') return 'str'
  if (attrType.value === 'text') return 'txt'
  if (attrType.value === 'skill') return 'choice'

  return attrType.value
}

export const getConfigEntries = (name: string): Object => {
  const config: Object = getServerConfig()
  const filteredConfig = reduce(
    config,
    (acc, value, key: string) => {
      if (CONFIG_ENTRIES[key]) acc[key] = value
      return acc
    },
    {},
  )

  return filteredConfig[name]
}

export const getConfigEntryOption = (name: string): Object => {
  const obj: Object = getConfigEntries(name)
  if (!obj) return null
  return map(obj, (label: string, value: string) => ({ value, label }))
}

export const attributeTypes: Array<AttributeTypes> = [
  { value: 'bool', label: 'bool', params: [] },
  { value: 'char', label: 'char', params: ['choice', 'colored'] },
  { value: 'date', label: 'date', params: ['choice', 'dateMin', 'dateMax'] },
  { value: 'datetime', label: 'datetime', params: ['choice', 'datetimeMin', 'datetimeMax'] },
  { value: 'duration', label: 'duration', params: ['choice'] },
  { value: 'email', label: 'email', params: ['choice'] },
  { value: 'float', label: 'float', params: ['choice', 'min', 'max', 'step', 'slider'] },
  { value: 'iban', label: 'IBAN', params: [] },
  { value: 'integer', label: 'integer', params: ['choice', 'min', 'max', 'step', 'slider', 'colored'] },
  { value: 'ip', label: 'ip', params: ['choice'] },
  { value: 'phone', label: 'phone', params: ['choice'] },
  { value: 'priority', label: 'priority', params: ['min', 'max', 'step', 'slider'] },
  { value: 'rating', label: 'rating', params: [] },
  { value: 'skill', label: 'skill', params: [] },
  { value: 'smartdate', label: 'smart date', params: [] },
  { value: 'ssn', label: 'security social number', params: [] },
  { value: 'text', label: 'rich text', params: ['choice'] },
  { value: 'time', label: 'time', params: ['choice', 'timeMin', 'timeMax'] },
  { value: 'timecode', label: 'timecode', params: ['framerate'] },
  { value: 'url', label: 'url', params: ['choice'] },
  // { value: 'log', label: 'log', params: [] }, // just for pipeline department
]

export const editorTypes: Array<{ value: EditorType, label: string, type?: Array<AttrType> }> = [
  { value: 'bool', label: 'bool', type: ['bool'] },
  { value: 'choice', label: 'choice' },
  { value: 'date', label: 'date', type: ['date'] },
  { value: 'datetime', label: 'datetime', type: ['datetime'] },
  { value: 'duration', label: 'duration', type: ['duration'] },
  { value: 'email', label: 'email', type: ['email'] },
  { value: 'float', label: 'float', type: ['float'] },
  { value: 'iban', label: 'IBAN', type: ['char'] },
  { value: 'int', label: 'int', type: ['integer'] },
  { value: 'phone', label: 'phone', type: ['char'] },
  { value: 'priority', label: 'priority', type: ['integer'] },
  { value: 'rating', label: 'rating', type: ['integer'] },
  { value: 'slider', label: 'slider', type: ['integer', 'float', 'duration'] },
  { value: 'smartdate', label: 'smart date', type: ['smartdate'] },
  { value: 'ssn', label: 'security social number', type: ['ssn'] },
  { value: 'str', label: 'str', type: ['char', 'ip'] },
  { value: 'time', label: 'time', type: ['time'] },
  { value: 'timecode', label: 'timecode', type: ['timecode'] },
  { value: 'txt', label: 'rich text', type: ['text'] },
  { value: 'url', label: 'url', type: ['url'] },
  // { value: 'log', label: 'log', type: ['log'] },  // just for pipeline department
]

export const editorParams: Array<FormDataProperty> = []

function resetStateValues(data) {
  return mapValues(data, (value, key) => (!['category', 'required'].includes(key) ? undefined : value))
}

export const attrEditsForm = (
  defaultData: Object,
  edit: boolean,
  categories?: Array<Category>,
  noNameVerification: ?boolean,
): Array<FormDataProperty> => {
  const attributeType = attributeTypes.find(
    (attrType) => defaultData.attrType && attrType.value === defaultData.attrType.value,
  )
  const params = attributeType?.params || []

  const categoriesOptions: null | Array<Option> = categories
    ? map(categories, (category) => ({ label: category.name, value: category.id }))
    : null

  const editorParams = params
    .filter((param) => {
      // In future, a slider component should be integrated to cells
      if (param === 'slider') return false
      if (defaultData.choice) return param === 'choice'
      return true
    })
    .map((param) => {
      switch (param) {
        case 'choice':
          return {
            key: 'choice',
            label: 'List of choices',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <ChoiceTypeInput type={data?.attrType?.value} data={data} setData={setData} />
            ),
          }
        case 'min':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'number',
            },
          }
        case 'max':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'number',
            },
          }
        case 'timeMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'time',
            },
          }
        case 'timeMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'time',
            },
          }
        case 'dateMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'string',
            elementProps: {
              type: 'date',
            },
          }
        case 'dateMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'string',
            elementProps: {
              type: 'date',
            },
          }
        case 'datetimeMin':
          return {
            key: 'min',
            label: 'Min value',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <Field type="datetime" dataKey="min" data={data} setData={setData} />
            ),
          }
        case 'datetimeMax':
          return {
            key: 'max',
            label: 'Max value',
            type: 'custom',
            element: (data: Object, setData: Function) => (
              <Field type="datetime" dataKey="max" data={data} setData={setData} />
            ),
          }
        case 'step':
          return {
            key: 'step',
            label: 'Step',
            type: 'string',
            elementProps: {
              type: 'number',
              placeholder: 'ex: 0.1',
            },
          }
        case 'colored':
          return {
            key: 'colored',
            label: 'Colored',
            type: 'checkbox',
          }
        case 'framerate':
          return {
            key: 'framerate',
            label: 'Framerate',
            type: 'string',
            elementProps: {
              type: 'number',
              placeholder: 'ex: 24',
            },
          }
        case 'hoursInDay':
          return {
            key: 'hoursInDay',
            label: 'Hours ratio in a day',
            type: 'string',
            elementProps: {
              type: 'number',
              placeholder: 'ex: 7.8',
            },
          }
        default:
          throw new Error('The attribute type does not exist.')
      }
    })

  const attributeTypeOption: Array<Object> = attributeTypes.filter(
    (attrType) => !(!categoriesOptions && attrType.value === 'skill'),
  )

  return [
    {
      key: 'name',
      label: 'Name',
      type: 'string',
      elementProps: {
        disabled: edit,
        isRequired: true,
        validators:
          !noNameVerification && !categoriesOptions
            ? {
                nameValidation: (value: string) => {
                  if (regex.is.attribute.test(value) === false) {
                    return 'Invalid format. (a-zA-Z0-9_)'
                  }
                  return false
                },
              }
            : undefined,
      },
    },
    {
      key: 'attrType',
      label: 'Type',
      type: 'select',
      elementProps: (data: Object, setData: Function) => ({
        isRequired: true,
        fullWidth: true,
        disabled: edit,
        onChange: (value: Object) => {
          setData({
            ...resetStateValues(data),
            attrType: value,
            name: data.name,
          })
        },
        placeholder: 'Attributes type',
        options: attributeTypeOption,
      }),
    },
    ...(categoriesOptions
      ? [
          {
            key: 'category',
            label: 'Category',
            type: 'select',
            elementProps: {
              options: categoriesOptions,
              isRequired: true,
              fullWidth: true,
              placeholder: 'Select a category',
            },
          },
          {
            key: 'required',
            label: 'Required',
            type: 'checkbox',
          },
          {
            key: 'isInCreateForm',
            label: 'In Create People Form',
            type: 'checkbox',
            elementProps: (data: Object, setData: Function) => {
              return {
                onChange: (value) => {
                  setData({ isInCreateForm: value })
                },
                checked: Boolean(data.isInCreateForm),
              }
            },
          },
          {
            key: 'createFormRank',
            label: 'Rank',
            type: 'string',
            isShow: (data) => Boolean(data.isInCreateForm),
            elementProps: {
              required: true,
              type: 'number',
              placeholder: 'ex: 1 (required if "In Create People Form" checked)',
            },
          },
          {
            key: 'fillingHelp',
            label: 'Filling Help',
            type: 'textarea',
            isShow: (data) => Boolean(data.isInCreateForm),
            elementProps: {
              placeholder: 'Help text for form people creation',
              style: { width: '100%' },
            },
          },
        ]
      : []),
    {
      key: 'userOrdering',
      label: 'Keep user ordering',
      type: 'checkbox',
      isShow: (data) => !!data.choice,
      elementProps: (data: Object, setData: Function) => {
        return {
          onChange: (value) => {
            setData({ userOrdering: value })
          },
          checked: Boolean(data.userOrdering),
        }
      },
    },
    ...editorParams,
  ]
}
