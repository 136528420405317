var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo } from 'react';
import Loader from 'app/components/Loader/Loader.jsx';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { permission } from 'app/containers/Permissions/index.js';
import { EditList } from '../EditList';
import { ModalEditDepartment } from './ModalEditDepartment';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDepartment, fetchAllDepartments } from 'app/store/reducers/departments';
import { departmentsReducer } from 'app/store/selectors/departments';
export function DepartmentsEditor() {
    const dispatch = useDispatch();
    const { data: departmentsRedux, fetchLoading } = useSelector(departmentsReducer);
    useEffect(() => {
        dispatch(fetchAllDepartments());
    }, []);
    useEffect(() => {
        documentTitle('Departments editor', 'page');
    }, []);
    const canEdit = useMemo(() => permission(['human resources_settings_departments_edit']), []);
    const onRemove = (id) => __awaiter(this, void 0, void 0, function* () {
        yield dispatch(deleteDepartment(id));
    });
    return fetchLoading ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx("div", { className: "padding10", style: { width: 1300, height: '100%' }, children: _jsx(EditList, { title: "Departments", unity: "Department", itemsList: departmentsRedux, useRedux: true, readOnly: !canEdit, titles: ['Name', 'Quotation reference', 'Color', 'Jobs'], columnsSizes: [400, 180, 180, 400], labelSelectors: [
                'name',
                'quoteReference',
                {
                    key: 'color',
                    parser: (department) => (_jsx("div", { style: {
                            backgroundColor: department.color,
                            height: 30,
                            width: 30,
                            borderRadius: '3px',
                            marginRight: 10,
                        } })),
                },
                {
                    key: 'jobs',
                    parser: (department) => {
                        return (_jsx("div", { className: "flex column", children: department.jobsInst.map((jobInst) => (_jsx("span", { className: "padding5", children: jobInst.name }))) }));
                    },
                },
            ], onRemove: (department) => onRemove(department === null || department === void 0 ? void 0 : department.id), EditModal: (item) => _jsx(ModalEditDepartment, { department: item }), resource: "departments" }) }));
}
