/** @flow */
import React from 'react'
import moment from 'moment'
import { Route, Switch, Redirect } from 'react-router-dom'
import { VerticalCollapse } from 'app/components/Transitions/VerticalCollapse.jsx'
import { NavTitle, NavItem, Container, Nav, LeftContent, RightContent, NavSubTitle } from 'app/components/LeftNav'
import { PeopleTableEditor } from 'app/pages/HR/HRSettings/PeopleTableEditor/PeopleTableEditor.jsx'
import { DepartmentsEditor } from './HRSettings/DepartmentsEditor/DepartmentsEditor.tsx'
import { JobsEditor } from './HRSettings/JobsEditor/JobsEditor.tsx'
import { ExternalJobsEditor } from './HRSettings/ExternalJobsEditor/ExternalJobsEditor.tsx'
import { TableTimeline } from './TableTimeline/TableTimeline.jsx'
import TimeRecap from './TimeRecap/TimeRecap.tsx'
import { TablePeople } from './TablePeople/TablePeople.tsx'
import { CollectiveAgreementTitlesEditor } from './HRSettings/CollectiveAgreementTitlesEditor/CollectiveAgreementTitlesEditor.tsx'

import classes from './HR.module.scss'

type Props = {||}

export function HR(props: Props): React$Node {
  return (
    <Container data-testid="page-human-resources">
      <LeftContent>
        {(isOpen) => (
          <>
            <VerticalCollapse open={isOpen} height={50}>
              <div className={classes.nameContrainer}>
                <div className={classes.overlay} />
                <div className={classes.projectName}>Human resources</div>
              </div>
            </VerticalCollapse>
            <Nav>
              <NavTitle
                label="Organization"
                icon="fas-stream"
                collapse={!isOpen}
                permissions={[
                  'human resources_organization_imputations_edit',
                  'human resources_organization_imputations_read',
                  'human resources_organization_contracts_edit persons informations',
                  'human resources_organization_contracts_edit contracts',
                  'human resources_organization_contracts_read',
                ]}
              >
                <NavItem
                  label="Productions Timeline"
                  permissions={[
                    'human resources_organization_imputations_edit',
                    'human resources_organization_imputations_read',
                  ]}
                  to="/hr/imputations"
                  icon="fas-calendar-week"
                  collapse={!isOpen}
                />
                <NavItem
                  label="People"
                  permissions={['human resources_organization_contracts_read']}
                  to="/hr/contracts"
                  icon="fas-user-friends"
                  collapse={!isOpen}
                />
              </NavTitle>
              <NavTitle
                label="Hours"
                icon="fas-business-time"
                collapse={!isOpen}
                permissions={['human resources_time recap__read']}
              >
                <NavItem
                  label="Time recap"
                  permissions={['human resources_time recap__read']}
                  to="/hr/time-recap"
                  icon="fas-hourglass-half"
                  collapse={!isOpen}
                />
                <NavItem
                  label="Allotment recap"
                  permissions={['human resources_allotment recap__read']}
                  to="/hr/allotment-time-recap"
                  icon="fas-clock"
                  collapse={!isOpen}
                />
              </NavTitle>
              <NavTitle
                label="Settings"
                icon="fas-cog"
                collapse={!isOpen}
                permissions={[
                  'human resources_settings_departments_edit',
                  'human resources_settings_departments_read',
                  'human resources_settings_posts_edit',
                  'human resources_settings_posts_read',
                  'human resources_settings_collective agreement titles_edit',
                  'human resources_settings_collective agreement titles_read',
                  'human resources_settings_person attributes_edit',
                  'human resources_settings_person attributes_read',
                  'human resources_settings_contract attributes_edit',
                  'human resources_settings_contract attributes_read',
                ]}
              >
                <NavSubTitle
                  label="Organization"
                  permissions={[
                    'human resources_settings_departments_edit',
                    'human resources_settings_departments_read',
                    'human resources_settings_posts_edit',
                    'human resources_settings_posts_read',
                    'human resources_settings_collective agreement titles_edit',
                    'human resources_settings_collective agreement titles_read',
                  ]}
                />
                <NavItem
                  label="Departments"
                  permissions={[
                    'human resources_settings_departments_edit',
                    'human resources_settings_departments_read',
                  ]}
                  to="/hr/settings/departments"
                  icon="fas-sitemap"
                  collapse={!isOpen}
                />
                <NavItem
                  label="Job Titles"
                  permissions={['human resources_settings_posts_edit', 'human resources_settings_posts_read']}
                  to="/hr/settings/jobs"
                  icon="fas-user-tag"
                  collapse={!isOpen}
                />
                <NavItem
                  label="External Job Titles"
                  permissions={['human resources_settings_posts_edit', 'human resources_settings_posts_read']}
                  to="/hr/settings/externalJobs"
                  icon="fas-user-edit"
                  collapse={!isOpen}
                />
                <NavItem
                  label="Collective Agreement Titles"
                  permissions={[
                    'human resources_settings_collective agreement titles_edit',
                    'human resources_settings_collective agreement titles_read',
                  ]}
                  to="/hr/settings/collective-agreement-titles"
                  icon="fas-handshake"
                  collapse={!isOpen}
                />
                <NavSubTitle label="Table Editors" permissions={['human resources_settings_table editor_people']} />
                <NavItem
                  label="People Table Editor"
                  permissions={['human resources_settings_table editor_people']}
                  to="/hr/settings/people-table-editor"
                  icon="fas-table"
                  collapse={!isOpen}
                />
              </NavTitle>
            </Nav>
          </>
        )}
      </LeftContent>
      <RightContent>
        <Switch>
          <Route
            exact={true}
            path="/hr/time-recap"
            render={({ match }) => <Redirect to={`${match.url}/${moment().format('MM-YYYY')}/all/`} />}
          />
          <Route path="/hr/time-recap/:month/:projectId?/:resourceId?" component={TimeRecap} />
          <Route
            exact={true}
            path="/hr/allotment-time-recap"
            render={({ match }) => <Redirect to={`${match.url}/${moment().format('MM-YYYY')}/all/`} />}
          />
          <Route
            path="/hr/allotment-time-recap/:month/:projectId?/:resourceId?"
            render={() => <TimeRecap allotment={true} />}
          />
          <Route path="/hr/imputations" component={TableTimeline} />
          <Route path="/hr/contracts/:peopleSchemaId?" component={TablePeople} />
          <Route path="/hr/settings/departments" component={DepartmentsEditor} />
          <Route path="/hr/settings/jobs" component={JobsEditor} />
          <Route path="/hr/settings/externalJobs" component={ExternalJobsEditor} />
          <Route path="/hr/settings/collective-agreement-titles" component={CollectiveAgreementTitlesEditor} />
          <Route path="/hr/settings/people-table-editor/:peopleSchemaId?" component={PeopleTableEditor} />
        </Switch>
      </RightContent>
    </Container>
  )
}
