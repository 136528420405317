var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CellText, CellSelect } from 'app/components/Table/Cells';
import moment from 'moment';
import { updateLeave } from 'app/store/reducers/leaves';
export default ({ userProjects, projects, formatView, deleteLeavesAction, dispatch }) => [
    {
        Header: 'Leaves',
        id: 'leaves',
        columns: [
            CellSelect({
                id: 'status',
                Header: 'Status',
                hiddenable: true,
                noFilterOption: true,
                accessor: 'approvalStatus',
                width: 110,
                actions: (instance, cell) => {
                    if (!formatView) {
                        return ['edit', deleteLeavesAction(instance, cell)];
                    }
                    return [deleteLeavesAction(instance, cell)];
                },
                options: () => [
                    { label: 'Waiting', value: 0 },
                    { label: 'Approved', value: 1 },
                    { label: 'Refused', value: 2 },
                ].map((status) => {
                    return status;
                }),
                customSave: (newValue, data) => {
                    const newData = Object.assign(Object.assign({}, data), { approvalStatus: newValue });
                    dispatch(updateLeave(newData));
                },
            }),
            CellText({
                id: 'startDate',
                Header: 'Start Date',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                accessor: (item) => moment.utc(item.startDate).format('L LT'),
                width: 130,
            }),
            CellText({
                id: 'endDate',
                Header: 'End Date',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                accessor: (item) => moment.utc(item.endDate).format('L LT'),
                width: 130,
            }),
            CellSelect({
                id: 'project',
                Header: 'Project',
                accessor: 'project',
                width: 150,
                actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                options: () => userProjects.map((userProject) => {
                    const project = projects.find((project) => project.id === userProject);
                    return { label: project === null || project === void 0 ? void 0 : project.name, value: project === null || project === void 0 ? void 0 : project.id };
                }),
                customSave: (newValue, data) => {
                    const newData = Object.assign(Object.assign({}, data), { project: newValue });
                    dispatch(updateLeave(newData));
                },
            }),
            CellText({
                id: 'comment',
                Header: 'Comment',
                hiddenable: true,
                noFilterOption: true,
                readOnly: false,
                accessor: 'comment',
                width: 250,
                actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                customSave: (newValue, data) => __awaiter(void 0, void 0, void 0, function* () {
                    const newData = Object.assign(Object.assign({}, data), { comment: newValue });
                    dispatch(updateLeave(newData));
                }),
            }),
        ],
    },
];
