/** @flow */
import React, { useMemo } from 'react'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import type { SchemaColumnSettings } from 'app/core/types/Schema'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'

type CollectiveAgreementType = {|
  name: string,
  type: 'title' | 'minimumSalary',
|}

export type CollectiveAgreementNodeData = {|
  collectiveAgreement: CollectiveAgreementType,
  contentType: 'collectiveAgreement',
|}

const collectiveAgreementItems: CollectiveAgreementType[] = [
  { name: 'Title', type: 'title' },
  { name: 'Minimum salary', type: 'minimumSalary' },
  { name: 'Minimum salary 35h', type: 'secondMinimumSalary' },
]

export function CollectiveAgreements(): React$Node {
  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: collectiveAgreementItems.map((collectiveAgreement) => {
        return new Node<$Shape<SchemaColumnSettings<CollectiveAgreementNodeData>>>({
          key: collectiveAgreement.name,
          name: collectiveAgreement.name,
          parent: rootNode,
          data: { collectiveAgreement, contentType: 'collectiveAgreement' },
          icon: 'fas-grip-vertical',
          type: NODE_ITEM,
        })
      }),
    })

    return rootNode
  }, [])

  return (
    <div style={{ padding: '0 10px 10px 10px', maxWidth: '100%', width: '100%' }}>
      <TreeDnD rootNode={rootNode} />
    </div>
  )
}
