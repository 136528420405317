// @flow
import { flatten, map } from 'lodash'
import { optionsPiority } from 'app/core/utils/optionsPriority'
import { optionsProgressionStatus } from 'app/core/utils/optionsProgressionStatus'
import { getResources } from 'app/store/selectors/getResources'
import { operators as op, presetOperators as pOp } from 'app/components/Table/TableView/Elements/Filters/operators.ts'
import type { ResourcesList, Attribute, AssetTypes, ColumnFilter, Option } from 'app/core/types'
import { optionsDynamicApprovalStatus } from 'app/core/utils/optionsDynamicApprovalStatus'
import { assetsTypes } from 'app/core/constants'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { postboardLinksTypesOptions } from 'app/core/constants/postBoardLinkTypes.js'
import { editorTypeToInputType } from 'app/components/Table/hooks/useFiltersUtils'

type Columns = Array<{|
  type: string,
  attr: string,
|}>

type Props = {|
  columns: Columns,
  attributes: ResourcesList<Attribute>,
  assetType: AssetTypes,
|}

export class Filters {
  params: Object = {}

  filters: { [key: string]: Array<ColumnFilter> } = {}

  constructor({ columns, attributes, assetType }: Props) {
    this.params = {
      assetType,
      columns,
      progressionStatus: getResources(undefined, 'progressionStatus'),
      attributes: map(attributes),
    }

    this.filters = {
      asset_name: [
        {
          label: 'Asset',
          type: 'column',
          operators: [
            ...map(pOp.text, (op) => ({
              ...op,
              attr: 'name',
              inputType: 'text',
            })),
            ...map(pOp.list, (op) => ({
              ...op,
              attr: 'uuid',
              multiple: true,
              inputType: 'asset',
              getSubLabel: (asset: Object) => {
                if (!asset) return undefined
                return asset.parent__name
              },
            })),
          ],
        },
        {
          label: 'Episode',
          type: 'column',
          operators: [
            ...map([op.exact, op.notExact], (op) => {
              return {
                ...op,
                attr: 'episode',
                inputType: 'asset',
                assetTypes: ['ep'],
              }
            }),
            ...map(pOp.list, (op) => {
              return {
                ...op,
                attr: 'episode',
                multiple: true,
                inputType: 'asset',
                assetTypes: ['ep'],
              }
            }),
          ],
        },
      ],
    }
  }

  renderAssetFilters(column: Object) {
    if (this.filters[`asset_${column.key}_${column.attr}_${column.columnName}`]) return

    switch (column.attr) {
      case 'assetFlagsInst':
        this.filters[`asset_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                multiple: true,
                inputType: 'flags',
                attr: 'flags__uuid',
              })),
            ],
          },
        ]
        break
      case 'assetType':
        this.filters[`asset_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                multiple: true,
                inputType: 'select',
                options: {
                  label: 'Asset type',
                  data: map(assetsTypes, (label: string, value: AssetTypes) => ({
                    value,
                    label,
                    icon: assetIcons(value),
                  })),
                },
                attr: 'assetType',
              })),
            ],
          },
        ]
        break
      case 'parentInst':
        this.filters[`asset_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                attr: 'parent__uuid',
                multiple: true,
                inputType: 'asset',
                assetTypes: ['ep', 'fo'],
              })),
              ...map(pOp.exact, (op) => ({
                ...op,
                attr: 'parent__name',
              })),
            ],
          },
        ]
        break
      default:
        break
    }
  }

  renderLastTakeFilters(column: Object) {
    if (this.filters[`task_${column.key}_${column.attr}_${column.columnName}`]) return

    const { progressionStatus } = this.params

    switch (column.attr) {
      case 'estimLength':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_estimLength`,
                inputType: 'duration',
              })),
            ],
          },
        ]
        break
      case 'number':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_number`,
                inputType: 'number',
              })),
            ],
          },
        ]
        break
      case 'takeFlagsInst':
        this.filters[`asset_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                multiple: true,
                inputType: 'flags',
                attr: `lastTake_${column.step}_flags__uuid`,
              })),
            ],
          },
        ]
        break
      case 'status':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_status`,
                inputType: 'select',
                options: {
                  label: 'Status',
                  data: optionsProgressionStatus(progressionStatus),
                },
                multiple: true,
                resourceValue: 'progressionStatus',
              })),
            ],
          },
        ]
        break
      case 'comment':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.text, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_comment`,
              })),
            ],
          },
        ]
        break
      case 'validationComment':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.text, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_validationComment`,
              })),
            ],
          },
        ]
        break
      case 'name':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.str, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_name`,
              })),
            ],
          },
        ]
        break
      case 'takeValidationMediasInst':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.relation, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_validationMedias`,
              })),
            ],
          },
        ]
        break
      case 'takeRefMediasInst':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.relation, (op) => ({
                ...op,
                attr: `lastTake_${column.step}_refMedias`,
              })),
            ],
          },
        ]
        break
      default:
        console.warn(`unknow lastTake attr ${column.key}_${column.attr}_${column.columnName}`)
    }
  }

  renderTasksFilters(column: Object) {
    if (this.filters[`task_${column.key}_${column.attr}_${column.columnName}`]) return

    const { progressionStatus } = this.params

    switch (column.attr) {
      case 'priority':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `step_${column.step}_priority`,
                options: {
                  label: 'Priority',
                  data: optionsPiority,
                },
                inputType: 'select',
                valueType: 'number',
              })),
            ],
          },
        ]
        break
      case 'status':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                attr: `step_${column.step}_status`,
                inputType: 'select',
                options: {
                  label: 'Status',
                  data: optionsProgressionStatus(progressionStatus),
                },
                multiple: true,
                resourceValue: 'progressionStatus',
              })),
            ],
          },
        ]
        break
      case 'assignedUser':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                attr: `step_${column.step}_assignedUser`,
                multiple: true,
                inputType: 'asset',
                assetTypes: ['us'],
              })),
              ...map(pOp.isNull, (op) => ({
                ...op,
                attr: `step_${column.step}_assignedUser`,
              })),
            ],
          },
        ]
        break
      case 'taskViewersInst':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.list, (op) => ({
                ...op,
                attr: `step_${column.step}_viewers`,
                multiple: true,
                inputType: 'asset',
                assetTypes: ['us'],
              })),
              ...map([op.isEmpty, op.isNotEmpty], (op) => ({
                ...op,
                attr: `step_${column.step}_viewers`,
              })),
            ],
          },
        ]
        break
      case 'endDate':
      case 'startDate':
      case 'earliestStartDate':
      case 'latestEndDate':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `step_${column.step}_${column.attr}`,
                inputType: 'date',
              })),
            ],
          },
        ]
        break
      case 'name':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.str, (op) => ({
                ...op,
                attr: `step_${column.step}_name`,
              })),
            ],
          },
        ]
        break
      case 'realEstimLength':
      case 'quoteEstimLength':
        this.filters[`task_${column.key}_${column.attr}_${column.columnName}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `step_${column.step}_${column.attr}`,
                inputType: 'duration',
              })),
            ],
          },
        ]
        break
      case 'estimLength':
      case 'activitiesInst':
      case 'difference':
        break
      default:
        console.warn(`unknow task attr ${column.key}_${column.attr}_${column.columnName}`)
    }
  }

  renderAttributeFilters(column: Object) {
    if (this.filters[`attributes_${column.name}`]) return

    const attribute: Attribute = this.params.attributes.find((attr) => attr.id === column.id)

    if (attribute.editorParams.choice) {
      this.filters[`attributes_${column.name}`] = [
        {
          label: column.columnName,
          type: 'column',
          operators: [
            ...map(pOp.list, (op) => ({
              ...op,
              attr: `attributes__${attribute.name}`,
              inputType: 'select',
              valueType: editorTypeToInputType(attribute.editorType),
              options: {
                label: attribute.name,
                data: attribute.editorParams.choice?.map((value): Option => ({ label: value, value })) || [],
              },
              multiple: true,
            })),
            ...map([op.hasKey, op.hasNotKey], (op) => ({
              ...op,
              filterValue: `"${attribute.name}"`,
              attr: `attributes`,
              label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
            })),
            ...map([op.isNull, op.isNotNull, op.exact, op.notExact], (op) => ({
              ...op,
              attr: `attributes__${attribute.name}`,
            })),
          ],
        },
      ]
      return
    }

    switch (attribute.attrType) {
      case 'float':
      case 'integer':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
                inputType: 'number',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'duration':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.number, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
                inputType: 'duration',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'date':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
                inputType: 'date',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'datetime':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
                inputType: 'datetime',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'smartdate':
        break
      case 'bool':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.boolean, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'text':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.text, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break
      case 'email':
      case 'url':
      case 'char':
      case 'ip':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.str, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
            ],
          },
        ]
        break
      case 'time':
        this.filters[`attributes_${attribute.name}`] = [
          {
            label: column.columnName,
            type: 'column',
            operators: [
              ...map(pOp.date, (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
                inputType: 'time',
              })),
              ...map([op.hasKey, op.hasNotKey], (op) => ({
                ...op,
                filterValue: `"${attribute.name}"`,
                attr: `attributes`,
                label: op.value === 'hasKey' ? 'Exist on asset' : "Don't exist on asset",
              })),
              ...map([op.isNull, op.isNotNull], (op) => ({
                ...op,
                attr: `attributes__${attribute.name}`,
              })),
            ],
          },
        ]
        break

      default:
        console.warn(`attribute attrType ${column.attrType} unknown`)
    }
  }

  renderStepFilters(column: Object) {
    if (this.filters[`steps_${column.name}`]) return

    const { progressionStatus } = this.params

    this.filters[`steps_${column.name}`] = [
      {
        label: `${column.columnName} - status`,
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `lastTake_${column.id}_status`,
            inputType: 'select',
            options: {
              label: 'Status',
              data: optionsProgressionStatus(progressionStatus),
            },
            multiple: true,
            resourceValue: 'progressionStatus',
          })),
        ],
      },
      {
        label: `${column.columnName} - status update date`,
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: `lastTake_${column.id}_statusUpdatedAt`,
            inputType: 'datetime',
          })),
        ],
      },
      {
        label: `${column.columnName} - assigned user`,
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `step_${column.id}_assignedUser`,
            multiple: true,
            inputType: 'asset',
            assetTypes: ['us'],
          })),
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: `step_${column.id}_assignedUser`,
          })),
        ],
      },
      {
        label: `${column.columnName} - brief`,
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: `lastTake_${column.id}_comment`,
          })),
        ],
      },
      {
        label: `${column.columnName} - take number`,
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: `lastTake_${column.id}_number`,
          })),
        ],
      },
    ]
  }

  renderDynamicApprovalFilters(column: Object) {
    if (this.filters[`dynamicApproval_${column.name}`]) return

    this.filters[`dynamicApproval_${column.name}`] = [
      {
        label: `${column.columnName} - status`,
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_status`,
            inputType: 'select',
            options: {
              label: 'Status',
              data: optionsDynamicApprovalStatus,
            },
            multiple: true,
          })),
        ],
      },
      {
        label: `${column.columnName} - status update date`,
        type: 'column',
        operators: [
          ...map(pOp.date, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_statusUpdatedAt`,
            inputType: 'datetime',
          })),
        ],
      },
      {
        label: `${column.columnName} - comment`,
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_comment`,
          })),
          ...map(pOp.empty, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_comment`,
          })),
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_comment`,
          })),
        ],
      },
      {
        label: `${column.columnName}`,
        type: 'column',
        operators: [
          ...map(pOp.isNull, (op) => ({
            ...op,
            attr: `dynamicApproval_${column.id}_uuid`,
          })),
        ],
      },
    ]
  }

  renderAssetLinksFilters(column: Object) {
    this.filters.links__uuid = [
      {
        label: 'Linked assets',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'links__uuid',
            multiple: true,
            inputType: 'asset',
            getSubLabel: (asset: Object) => {
              if (!asset) return undefined
              return asset.parent__name
            },
          })),
        ],
      },
    ]
  }

  renderPostboardNotes(column: Object) {
    this.filters.assetPostBoardLinks__linkType = [
      {
        label: 'Production links - Type',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `assetPostBoardLinks__linkType`,
            inputType: 'select',
            options: {
              label: 'Type',
              data: postboardLinksTypesOptions,
            },
            multiple: true,
          })),
        ],
      },
      {
        label: 'Production links - Episode',
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: 'assetPostBoardLinks__episode__uuid',
            multiple: true,
            inputType: 'asset',
            assetTypes: ['ep'],
          })),
        ],
      },
      {
        label: 'Notes',
        type: 'column',
        operators: [
          ...map(pOp.text, (op) => ({
            ...op,
            attr: 'assetPostBoardLinks__notes__text',
            inputType: 'text',
          })),
        ],
      },
      {
        label: `${column.columnName}`,
        type: 'column',
        operators: [
          ...map([op.isNull, op.isNotNull], (op) => ({
            ...op,
            attr: `postBoardNote_${column.step.id}_uuid`,
          })),
          ...map([op.isEmpty, op.isNotEmpty], (op) => ({
            ...op,
            attr: `postBoardNote_${column.step.id}_text`,
          })),
          ...map(pOp.text, (op) => ({
            ...op,
            attr: `postBoardNote_${column.step.id}_text`,
            inputType: 'text',
          })),
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `assetPostBoardLinks__linkType`,
            options: {
              label: 'Type',
              data: postboardLinksTypesOptions,
            },
            multiple: true,
          })),
        ],
      },
    ]
  }

  renderCalculatedField(column: Object) {
    const { name, columnName, id, calculatedFieldType } = column
    if (this.filters[`calculatedField_${name}`]) return

    this.filters[`calculatedField_${name}`] = []

    if (calculatedFieldType === 'dynappsummary') {
      this.filters[`calculatedField_${name}`].push({
        label: columnName,
        type: 'column',
        operators: [
          ...map(pOp.list, (op) => ({
            ...op,
            attr: `calculatedField_${id}_status`,
            formatMultipleValues: (value, filterKey) => {
              if (filterKey) return `${filterKey}=${JSON.stringify(value.split(','))}`
              return JSON.stringify(value.split(','))
            },
            inputType: 'select',
            options: {
              label: 'Status',
              data: optionsDynamicApprovalStatus,
            },
            multiple: true,
          })),
        ],
      })
    } else if (calculatedFieldType === 'duration') {
      this.filters[`calculatedField_${name}`].push({
        label: column.columnName,
        type: 'column',
        operators: [
          ...map(pOp.number, (op) => ({
            ...op,
            attr: `calculatedField_${id}`,
            inputType: 'duration',
          })),
        ],
      })
    }
  }

  getFilters(): Array<ColumnFilter> {
    const { columns } = this.params

    columns.forEach((column) => {
      switch (column.type) {
        case 'assets':
          this.renderAssetFilters(column)
          return
        case 'lastTake':
          this.renderLastTakeFilters(column)
          return
        case 'tasks':
          this.renderTasksFilters(column)
          return
        case 'attributes':
          this.renderAttributeFilters(column)
          return
        case 'steps':
          this.renderStepFilters(column)
          return
        case 'dynamicApprovals':
          this.renderDynamicApprovalFilters(column)
          return
        case 'assetLinks':
          this.renderAssetLinksFilters(column)
          return
        case 'postBoardNotes':
          this.renderPostboardNotes(column)
          return
        case 'calculatedField':
          this.renderCalculatedField(column)
          return
        case 'aggregation':
          return

        default:
          console.warn(`Can't find this column type (${column.type}) for filters`)
      }
    })

    const list: Array<Object> = flatten(map(this.filters))

    return list
  }
}
