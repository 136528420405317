var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    data: [],
    error: null,
};
export const fetchAllDepartmentJobs = createAsyncThunk('departmentJobs/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departmentJobs.fetchAll({ page_size: 1000 });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createDepartmentJobs = createAsyncThunk('departmentJobs/create', (jobs_1, _a) => __awaiter(void 0, [jobs_1, _a], void 0, function* (jobs, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departmentJobs.collection.create(jobs);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteDepartmentJobs = createAsyncThunk('departmentJobs/delete', (ids_1, _a) => __awaiter(void 0, [ids_1, _a], void 0, function* (ids, { fulfillWithValue, rejectWithValue }) {
    try {
        const idsObj = ids.map((el) => ({ id: el }));
        yield api.departmentJobs.collection.delete(idsObj);
        return fulfillWithValue(ids);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { reducer } = createSlice({
    name: 'departmentJobs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // FETCH ALL
        builder.addCase(fetchAllDepartmentJobs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllDepartmentJobs.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.loading = false;
        });
        builder.addCase(fetchAllDepartmentJobs.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
        // DELETE
        builder.addCase(deleteDepartmentJobs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteDepartmentJobs.fulfilled, (state, { payload }) => {
            state.data = state.data.filter((job) => !payload.includes(job.id));
            state.loading = false;
        });
        builder.addCase(deleteDepartmentJobs.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
    },
});
export default reducer;
