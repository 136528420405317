var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useState } from 'react';
import innerText from 'react-innertext';
import { filter, orderBy } from 'lodash';
import cx from 'classnames';
import { MUIButton, ButtonList, Input } from 'app/components/Form';
import resources from 'app/store/resources';
import Widget from 'app/components/Widget/Widget.jsx';
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx';
import modulableTreeClasses from 'app/components/ModulableTree/styles/labelFullWidth.module.scss';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { ModalTrigger, confirm, openModal } from 'app/components/Modal';
import classes from './EditList.module.scss';
import { MUICheckbox } from 'app/components/Form/Checkbox/MUICheckbox.jsx';
export function EditList(props) {
    const { itemsList, resource, EditModal, onRemove, titles = ['Name'], title, labelSelectors = ['name'], columnsSizes, readOnly, additionnalActions, unity, renderLabel, useRedux, } = props;
    const [search, setSearch] = useState('');
    const [sortIndex, setSortIndex] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const normalizeString = (string) => string === null || string === void 0 ? void 0 : string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const onValidate = (item) => __awaiter(this, void 0, void 0, function* () {
        if (useRedux) {
            yield (onRemove === null || onRemove === void 0 ? void 0 : onRemove(item));
        }
        else {
            const res = yield resources[resource].delete(item.id);
            onRemove === null || onRemove === void 0 ? void 0 : onRemove(item);
            return res;
        }
    });
    function removeItem(item) {
        confirm({
            title: 'Delete',
            titleColor: '#E56D7A',
            render: (_jsxs("div", { children: ["Delete ", _jsx("b", { children: item[labelSelectors[0]] }), " from list ?"] })),
            onValidate: () => onValidate(item),
            validateLabel: 'Delete',
            validateLabelColor: '#E56D7A',
            validateMessage: unity ? `${unity} deleted` : '',
        });
    }
    function updateItem(item) {
        openModal(EditModal(item));
    }
    function renderAddItems() {
        return (_jsxs(ButtonList, { children: [!readOnly ? (_jsx(ModalTrigger, { modal: EditModal(), children: _jsx(MUIButton, { icon: "fas-plus", children: "New" }) })) : null, _jsx(Input, { dataCy: "input-search", placeholder: "Search an item", value: search, onChange: (e) => setSearch(e.target.value), adornmentIcon: "fas-search" })] }));
    }
    const currentSelector = labelSelectors[0];
    function getItems() {
        let resolvedItemList = orderBy(filter(itemsList, (item) => {
            return (!search ||
                String(normalizeString(item[currentSelector])).toLowerCase().includes(normalizeString(search).toLowerCase()));
        }), (item) => { var _a; return (_a = item[labelSelectors[sortIndex]]) === null || _a === void 0 ? void 0 : _a.toLowerCase(); }, sortDirection === 'asc' ? 'desc' : 'asc');
        const items = resolvedItemList.map((item, index) => {
            return {
                label: (_jsx("div", { className: cx(classes.item, !columnsSizes ? classes.autoSizes : null, {
                        [classes.multipleItems]: titles.length > 1,
                    }), children: labelSelectors.map((selector, index) => {
                        const key = typeof selector === 'object' ? selector.key : selector;
                        const parser = typeof selector === 'object' ? selector.parser : undefined;
                        const boolean = typeof item[key] === 'boolean' ? (_jsx(MUICheckbox, { readOnly: true, checked: Boolean(item[key]) })) : undefined;
                        return (_jsx("div", { className: classes.itemCell, style: { position: 'relative', minWidth: columnsSizes === null || columnsSizes === void 0 ? void 0 : columnsSizes[index], maxWidth: columnsSizes === null || columnsSizes === void 0 ? void 0 : columnsSizes[index] }, children: parser ? parser(item) : renderLabel ? renderLabel(item) : boolean || item[key] }, key));
                    }) })),
                actions: readOnly
                    ? undefined
                    : [
                        {
                            key: 'delete',
                            onClick: () => removeItem(item),
                        },
                        {
                            key: 'edit',
                            onClick: () => updateItem(item),
                        },
                        ...(additionnalActions || []),
                    ],
                key: item.id,
            };
        });
        return [
            {
                label: (_jsxs("div", { className: cx(classes.item, !columnsSizes ? classes.autoSizes : null, {
                        [classes.multipleItems]: titles.length > 1,
                    }), children: [titles.map((title, index) => (_jsx("div", { className: classes.itemCell, style: {
                                fontWeight: 'bold',
                                fontSize: 15,
                                userSelect: 'none',
                                minWidth: columnsSizes === null || columnsSizes === void 0 ? void 0 : columnsSizes[index],
                                maxWidth: columnsSizes === null || columnsSizes === void 0 ? void 0 : columnsSizes[index],
                            }, onClick: () => {
                                if (sortIndex === index)
                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                else {
                                    setSortIndex(index);
                                    setSortDirection('desc');
                                }
                            }, children: _jsxs("div", { className: "flex row noWrap fullWidth overflowHidden relative center", children: [_jsx("div", { children: title }), sortIndex === index ? (_jsx("div", { className: classes.sortIndicator, children: _jsx(FontIcon, { icon: sortDirection === 'desc' ? 'fas-sort-down' : 'fas-sort-up' }) })) : null] }) }, innerText(title)))), columnsSizes && !readOnly ? _jsx("div", { style: { width: 70, height: '100%' } }) : null] })),
                items,
            },
        ];
    }
    return (_jsx(Widget, { defaultWidth: "100%", children: _jsx(ModulableTree, { classes: modulableTreeClasses, title: _jsx("div", { className: classes.title, children: title }), treeProps: {
                hideOrigin: true,
                noExpand: true,
                noLevel: true,
            }, Header: renderAddItems(), firstItemStyle: {
                backgroundColor: '#f7f7f7',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                position: 'sticky',
                top: 0,
                zIndex: 2,
            }, items: getItems(), scrollable: true }) }));
}
