/** @flow */
import React, { useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { forEach, map, filter, reduce, isNumber, sortBy, find, values } from 'lodash'
import cx from 'classnames'
import history from 'app/main/routerHistory'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { openModal } from 'app/components/Modal'
import { Duration } from 'app/libs/helpers'
import { CellText } from 'app/components/Table'
import type { ID, Asset, ResourcesList, ActivityApproval, Activity } from 'app/core/types'
import type { ColumnHeader, TableRow, Cell, TableInstance, State } from 'app/components/Table'
import { permission } from 'app/containers/Permissions/index.js'
import { getResources } from 'app/store/selectors/getResources.js'
import TextRead from 'app/components/TextEditor/TextRead.jsx'

import { success } from 'app/components/Notifications/notify'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { ModalActivityValidation } from './ModalActivityValidation.jsx'
import classes from './TableTimeRecap.module.scss'
import { TableQuickLook } from './TableQuickLook.jsx'
import { fillBusinessDayOption } from './fillBusinessDaysAction.jsx'
import { ModalAddAbsenceActivity } from '../../MyHours/ModalAddAbsenceActivity.tsx'
import { confirmDelete } from '../../../components/Modal/confirmDelete.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLeave, updateLeave } from '../../../store/reducers/leaves.ts'
import {
  fetchAllUsersActivities,
  fetchAllUsersActivitiesStats,
  fetchUsersActivities,
  fetchUsersActivitiesStats,
} from '../../../store/reducers/usersWithActivities.ts'
import { setTableConfig } from 'app/store/reducers/tablesPrefs.ts'
import { setAllUsersActivities } from 'app/store/reducers/usersWithActivities.ts'
import { TableRedux } from 'app/components/Table/TableRedux.tsx'
import { userPrefLocalResource } from '../../../core/utils/localUserPrefs.js'
import {
  createActivityApproval,
  deleteActivityApproval,
  updateActivityApproval,
} from '../../../store/reducers/activitiesApprovals.ts'
import { tableUserPrefsDataSelector } from 'app/store/selectors/tableUserPrefs.ts'
import { taskTypesDataSelector } from 'app/store/selectors/taskTypes.ts'

export const tableId = 'table-time-recap'

export type Props = {|
  month: string,
  hideEmpty: boolean,
  resourceId: ?ID,
  ExtendsToolbar: React$Element<any>,
  ToolbarFilters: React$Element<any>,
  userSearch: ?Asset,
  projectId: ?ID | 'all',
  getTools: ({
    updateTable: () => void,
    getMetadataFromActivity: (
      activity: Activity,
    ) => $Exact<{ labelRow: string, labelRowConcat: string, user: ID, date: string }>,
  }) => void,
  reloadActivities: () => void,
  selectedRow: ?('otherProjects' | 'otherActivities' | 'total'),
  selectedUser: ?ID,
  selectedDay: ?number,
  allotment?: boolean,
  showQuickLook: boolean,
|}

type ActivitiesByProject = {|
  [rowLabel: string]: {
    [date: string]: Array<Activity>,
  },
|}

type ActivitiesDurationByProject = {|
  [rowLabel: string]: $Exact<{
    index: number,
    projectInst: ?Asset,
    [date: string]: number,
  }>,
|}

export type CellValue = {|
  id: string,
  index: number,
  rowLabel: string,
  project: ?ID,
  projectInst: ?Asset,
  user: ID,
  userInst: Asset,
  activities: {
    [date: string]: {|
      duration: number,
      totalHours: number,
      totalHoursLimited: number,
      getProjectHours: (
        subRow: Array<TableRow>,
        project: ?Asset,
      ) => { projectHoursLimited: ?number, projectTotalHours: number },
    |},
  },
  activitiesObjects: { [date: string]: Array<Activity> },
  allActivities: { [date: string]: Array<Activity> },
  activityApprovals: Array<ActivityApproval>,
  activityApprovalsByDate: { [date: string]: Array<ActivityApproval> },
|}

export function TableTimeRecap(props: Props): React$Node {
  const {
    month,
    hideEmpty,
    resourceId,
    ExtendsToolbar,
    ToolbarFilters,
    projectId,
    getTools,
    reloadActivities,
    selectedRow,
    selectedUser,
    selectedDay: _selectedDay,
    allotment,
    showQuickLook,
    searchedName,
  } = props

  const dispatch = useDispatch()
  const table = userPrefLocalResource.getData('tables')?.[tableId]
  const tableUserPrefs = useSelector(tableUserPrefsDataSelector(tableId))
  const taskTypes = useSelector(taskTypesDataSelector)
  const { data: reduxLeaves } = useSelector((state) => state.leaves)
  const { data: reduxActivitiesApprovals } = useSelector((state) => state.activitiesApprovals)
  const { data: reduxActivities } = useSelector((state) => state.reduxActivities)
  const {
    data: allUsersWithActivities,
    count,
    stats,
    statsIsLoading,
    httpProgress,
    fetchLoading,
  } = useSelector((state) => state.allUsersWithActivities)

  const { startDate, endDate, startDateString, endDateString } = useMemo(() => {
    const startDate = moment(month, 'MM-YYYY').startOf('month')
    const endDate = moment(month, 'MM-YYYY').endOf('month')
    return {
      startDate,
      endDate,
      startDateString: startDate.format('YYYY-MM-DD'),
      endDateString: endDate.format('YYYY-MM-DD'),
    }
  }, [month])

  const queries = {
    startDate: moment(startDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
    page_size: tableUserPrefs?.pageSize || table?.pageSize || 50,
    hideEmpty,
    page: searchedName ? 1 : tableUserPrefs?.page || 1,
    name: searchedName ?? undefined,
    taskTypes: allotment ? 'alt' : undefined,
  }

  useEffect(() => {
    ;(async () => {
      if (projectId === 'all') {
        dispatch(fetchAllUsersActivities(queries))
      } else if (resourceId) {
        dispatch(fetchUsersActivities({ id: resourceId, queries }))
      }
    })()
  }, [tableUserPrefs?.pageSize, tableUserPrefs?.page, searchedName, resourceId])

  useEffect(() => {
    ;(async () => {
      if (projectId === 'all') {
        dispatch(fetchAllUsersActivitiesStats(queries))
      } else if (resourceId) {
        dispatch(fetchUsersActivitiesStats({ id: resourceId, queries }))
      }
    })()
  }, [searchedName, resourceId])

  useEffect(() => {
    return () => {
      dispatch(setTableConfig({ tableId, data: { page: 1 } }))
      dispatch(setAllUsersActivities([]))
    }
  }, [])

  const projects = useMemo(() => getResources<ResourcesList<Asset>>(undefined, 'assets', { assetType: 'pj' }), []) // prettier-ignore
  const selectedProject = useMemo(() => (projectId ? projects[projectId] : null), [projects, projectId])

  const tableRef = useRef()
  const tableInstance = useRef()
  const currentSelectedCells = useRef<$ElementType<State, 'selectedCells'>>()

  type Day = { isWeekend: boolean, isCurrentDay: boolean, date: moment, dd: string, D: string }
  const { days, maxHoursWorkedInMonth } = useMemo(() => {
    let maxHoursWorkedInMonth = 0
    const accumulator = moment(startDate)
    const days: { [date: string]: Day } = {}
    const now = moment()

    while (accumulator.toDate() < endDate.toDate()) {
      if ([1, 2, 3, 4].includes(accumulator.day())) maxHoursWorkedInMonth += 8
      if (accumulator.day() === 5) maxHoursWorkedInMonth += 7
      const momentDate = moment(accumulator)
      days[momentDate.format('YYYY-MM-DD')] = {
        isWeekend: [0, 6].includes(momentDate.day()),
        isCurrentDay: now.isSame(momentDate, 'day'),
        date: momentDate,
        dd: momentDate.format('dd'),
        D: momentDate.format('D'),
      }
      accumulator.add(1, 'day')
    }

    return { days, maxHoursWorkedInMonth }
  }, [startDate, endDate])

  const selectedDay = useMemo(() => {
    if (!_selectedDay) return null
    return moment(month, 'MM-YYYY').date(_selectedDay)
  }, [_selectedDay, month])

  function getActivityApprovalsByDate(activityApprovals: Array<ActivityApproval>) {
    return reduce(
      Object.keys(days),
      (acc, dateStr) => ({
        ...acc,
        [dateStr]: activityApprovals.filter((aa) => aa.startDate <= dateStr && aa.endDate >= dateStr),
      }),
      {},
    )
  }

  function onValidateActivityApproval(value, instance, type) {
    const { selectedCells } = instance.getLastestSelection()

    const toUpdate: Array<$Shape<ActivityApproval>> = []
    const toCreate: Array<$Shape<ActivityApproval>> = []

    const alreadyDone = {}

    forEach(selectedCells, (cell) => {
      const { row, column } = cell
      const { value: cellValue } = cell
      const { user } = cellValue
      const { id: cellDate } = column

      const startDate = moment(cellDate, 'YYYY-MM-DD').startOf(type).format('YYYY-MM-DD')
      const endDate = moment(cellDate, 'YYYY-MM-DD').endOf(type).format('YYYY-MM-DD')

      const identifier = `${startDate}-${endDate}-${user}`
      if (alreadyDone[identifier]) return
      alreadyDone[identifier] = true

      const existantActivityApproval = row.original.activityApprovals.find(
        (aa) => aa.startDate === startDate && aa.endDate === endDate,
      )

      const newActivityApproval = { ...value, startDate, endDate, user, id: existantActivityApproval?.id }
      if (existantActivityApproval) toUpdate.push(newActivityApproval)
      else toCreate.push(newActivityApproval)
    })

    toCreate.length && dispatch(createActivityApproval(toCreate))
    toUpdate.length && dispatch(updateActivityApproval(toUpdate))
  }

  function validate(cell: Cell, instance: TableInstance, type: 'week' | 'month', activityApproval: ?ActivityApproval) {
    const { column } = cell
    const { id: date } = column
    const cellValue: CellValue = cell.value
    const { userInst } = cellValue

    const momentStartDate = moment(date, 'YYYY-MM-DD').startOf(type)
    const momentEndDate = moment(date, 'YYYY-MM-DD').endOf(type)

    return openModal(
      <ModalActivityValidation
        activityApproval={activityApproval}
        startDate={momentStartDate}
        endDate={momentEndDate}
        onChange={(value) => onValidateActivityApproval(value, instance, type)}
        type={type}
        user={userInst}
      />,
    )
  }

  function quickValidate(cell: Cell, instance: TableInstance, type: 'week' | 'month', valid: boolean) {
    onValidateActivityApproval({ isApproved: valid }, instance, type)
  }

  function removeValidation(cell: Cell, instance: TableInstance, type: 'week' | 'month') {
    const { getLastestSelection } = instance
    const { selectedCells } = getLastestSelection()

    const toDelete: Array<ID> = []

    const alreadyDone = {}
    forEach(selectedCells, (cell) => {
      const { row, column } = cell
      const { id: cellDate } = column

      const startDate = moment(cellDate, 'YYYY-MM-DD').startOf(type).format('YYYY-MM-DD')
      const endDate = moment(cellDate, 'YYYY-MM-DD').endOf(type).format('YYYY-MM-DD')

      const identifier = `${startDate}-${endDate}-${row.id}`
      if (alreadyDone[identifier]) return
      alreadyDone[identifier] = true

      row.original.activityApprovals.forEach((activityApproval) => {
        const { user, id } = activityApproval
        if (activityApproval.startDate === startDate && activityApproval.endDate === endDate) {
          toDelete.push(id)
        }
      })
    })

    toDelete.length && dispatch(deleteActivityApproval(toDelete))
  }

  function getHours(activities: number, hoursInDay: number) {
    const durationInst = new Duration(activities)
    const { hours }: { hours: number } = durationInst.getData(['hours'])
    const hoursLimited = durationInst.isGratherThan({ hours: hoursInDay }) ? hoursInDay : hours
    return [hoursLimited, hours]
  }

  const getCellStyles = (instance, cell, cellProps) => {
    const { column, row, value } = cell
    const { id: day = '' } = column
    const { isRowExpanded } = cellProps
    const { activityApprovals } = value
    let approvedStyle

    const leaveIsNotInsideApprovalWeekOrMonth = activityApprovals.some((aa) => {
      return aa.startDate <= day && aa.endDate >= day && aa.startDate !== aa.endDate
    })

    const isNotApproved = activityApprovals.some((aa) => {
      return !aa.isApproved && aa.startDate <= day && aa.endDate >= day && aa.startDate !== aa.endDate
    })

    if (activityApprovals.length > 0) {
      approvedStyle = {
        backgroundColor: leaveIsNotInsideApprovalWeekOrMonth ? '#00de0069' : 'transparent',
      }
      if (isNotApproved) {
        approvedStyle = {
          backgroundColor: '#ff7c7c42',
        }
      }
    }

    if (row.pivotSubRow) {
      approvedStyle = null
    }

    if (isRowExpanded || (!selectedProject && !row.pivotSubRow)) {
      return { fontWeight: 'bold', ...approvedStyle }
    }

    return { fontWeight: 'normal', ...approvedStyle }
  }

  function onSelectCell(cell, instance) {
    if (cell.column.cellType !== 'decimalHours') return
    const { selectedCells } = instance.getLastestSelection()

    currentSelectedCells.current = selectedCells

    if (Object.keys(selectedCells || {}).length > 1) return

    const { value, column } = cell
    const { id } = column
    const { rowLabel, user } = value
    let selectedRow = 'total'
    const date = moment(id, 'YYYY-MM-DD')

    if (rowLabel === 'Other projects') selectedRow = 'otherProjects'
    else if (rowLabel === 'Other activities') selectedRow = 'otherActivities'

    const project = find(projects, (pr) => pr.name === rowLabel.split('\n')[0])
    if (project) selectedRow = project.name

    history.pushWithQuery({ selectedUser: user, selectedDay: date.format('D'), selectedRow })
  }

  function getTotalHours(cell: Cell) {
    const { row, value } = cell
    const hours = Math.round((reduce(value.activities, (acc, activity) => acc + activity.totalHours, 0) || 0) * 10) / 10

    let projectHours
    if (selectedProject && row.subRows?.length > 1 && row.pivot) {
      const subRowIndex = row.subRows.findIndex((row) => row.original.project === selectedProject.id)
      if (subRowIndex > -1 && row.subRows[subRowIndex]?.original) {
        const subRowValue = row.subRows[subRowIndex].original
        const totalRealDurationProject = reduce(subRowValue.activities, (acc, activity) => acc + activity.totalHours, 0)
        projectHours = Math.round((totalRealDurationProject || 0) * 10) / 10
      }
    }

    return { hours, projectHours }
  }

  function getProjectHours(dateStr: string, hoursInDay: number, project: ?Asset) {
    return (subRows: Array<TableRow>) => {
      let projectHoursLimited
      let projectTotalHours = 0

      if (project && subRows.length >= 1) {
        const subRowIndex = subRows.findIndex((row) => {
          return row.original.project === project.id
        })

        const newActivity = subRows[subRowIndex]?.original.activities[dateStr]
        if (newActivity) {
          ;[projectHoursLimited, projectTotalHours] = getHours(newActivity.duration, hoursInDay)
        }
      }
      return { projectHoursLimited, projectTotalHours }
    }
  }

  function getMetadataFromActivity(activity: Activity) {
    const { user, date } = activity
    let labelRow

    if (activity.project) {
      const project = projects[activity.project]
      if (project) {
        labelRow = project.name
      } else {
        labelRow = 'Other projects'
      }
    } else {
      labelRow = 'Other activities'
    }

    const labelRowConcat =
      labelRow === 'Other projects' ? 'otherProjects' : labelRow === 'Other activities' ? 'otherActivities' : labelRow

    return { labelRow, labelRowConcat, user, date }
  }

  function calculateActivities(activities: { [dateStr: string]: number }, projectInst: ?Asset) {
    return reduce(
      activities,
      (newActivities, duration, dateStr) => {
        const { date: day } = days[dateStr] || {}
        const hoursInDay = day.day() === 5 ? 7 : 8
        const [totalHoursLimited, totalHours] = getHours(duration, hoursInDay)

        return {
          ...newActivities,
          [dateStr]: {
            duration,
            totalHours,
            totalHoursLimited,
            getProjectHours: getProjectHours(dateStr, hoursInDay, projectInst),
          },
        }
      },
      {},
    )
  }

  function formatRowData(user) {
    if (!user) return []

    const defaultLabel =
      projectId === 'all' ? 'All projects' : selectedProject ? `${selectedProject.name}\nTotal` : 'Total'

    const activitiesDurationByProject: ActivitiesDurationByProject = {}
    const activitiesByProject: ActivitiesByProject = {}

    // TO REFACT
    const activitiesDurationByProject2: ActivitiesDurationByProject = {}
    const activitiesByProject2: ActivitiesByProject = {}
    // END

    activitiesByProject[defaultLabel] = {}
    activitiesDurationByProject[defaultLabel] = {
      index: 0,
      projectInst: selectedProject || null,
    }

    // TO REFACT

    activitiesByProject2[defaultLabel] = {}
    activitiesDurationByProject2[defaultLabel] = {
      index: 0,
      projectInst: selectedProject || null,
    }
    // END

    if (selectedProject) {
      activitiesDurationByProject[selectedProject.name] = { index: 1, projectInst: selectedProject }
      activitiesByProject[selectedProject.name] = {}

      // TO REFACT
      activitiesDurationByProject2[selectedProject.name] = { index: 1, projectInst: selectedProject }
      activitiesByProject2[selectedProject.name] = {}
      // END
    }

    const userActivities = reduxActivities.filter((activity) => activity.user === user.id)

    const activities = filter(userActivities, (activity) => {
      return (
        activity.date >= startDateString &&
        activity.activityType !== 'lv' &&
        activity.date <= endDateString &&
        (allotment ? taskTypes[activity?.taskType] === 'alt' : taskTypes[activity?.taskType] !== 'alt')
      )
    })

    // TO REFACT
    const activities2 = filter(userActivities, (activity) => {
      return (
        activity.date >= startDateString &&
        activity.date <= endDateString &&
        (allotment ? taskTypes[activity?.taskType] === 'alt' : taskTypes[activity?.taskType] !== 'alt')
      )
    })
    // END

    forEach(activities, (activity) => {
      let projectName

      if (activity.project) {
        const project = projects[activity.project]

        if (project) {
          projectName = project.name
        } else {
          projectName = `Other projects`
        }
      } else {
        projectName = `Other activities`
      }

      if (!projectName) return

      if (!activitiesDurationByProject[projectName]) {
        activitiesDurationByProject[projectName] = {
          index: projectName === `Other projects` ? 4 : projectName === `Other activities` ? 3 : 2,
          projectInst: projects[activity.project] || null,
        }
        activitiesByProject[projectName] = {}
      }

      if (!activitiesDurationByProject[projectName][activity.date]) {
        activitiesDurationByProject[projectName][activity.date] = 0
        activitiesByProject[projectName][activity.date] = []
      }
      if (!activitiesDurationByProject[defaultLabel][activity.date]) {
        activitiesDurationByProject[defaultLabel][activity.date] = 0
        activitiesByProject[defaultLabel][activity.date] = []
      }
      activitiesDurationByProject[projectName][activity.date] += activity.duration
      activitiesDurationByProject[defaultLabel][activity.date] += activity.duration

      activitiesByProject[projectName][activity.date].push(activity)
      activitiesByProject[defaultLabel][activity.date].push(activity)
    })

    // TO REFACT

    forEach(activities2, (activity) => {
      let projectName

      if (activity.project) {
        const project = projects[activity.project]

        if (project) {
          projectName = project.name
        } else {
          projectName = `Other projects`
        }
      } else {
        projectName = `Other activities`
      }

      if (!projectName) return

      if (!activitiesDurationByProject2[projectName]) {
        activitiesDurationByProject2[projectName] = {
          index: projectName === `Other projects` ? 4 : projectName === `Other activities` ? 3 : 2,
          projectInst: projects[activity.project] || null,
        }
        activitiesByProject2[projectName] = {}
      }

      if (!activitiesDurationByProject2[projectName][activity.date]) {
        activitiesDurationByProject2[projectName][activity.date] = 0
        activitiesByProject2[projectName][activity.date] = []
      }
      if (!activitiesDurationByProject2[defaultLabel][activity.date]) {
        activitiesDurationByProject2[defaultLabel][activity.date] = 0
        activitiesByProject2[defaultLabel][activity.date] = []
      }
      activitiesDurationByProject2[projectName][activity.date] += activity.duration
      activitiesDurationByProject2[defaultLabel][activity.date] += activity.duration

      activitiesByProject2[projectName][activity.date].push(activity)
      activitiesByProject2[defaultLabel][activity.date].push(activity)
    })
    // END

    const activityApprovals = reduxActivitiesApprovals.filter((aa) => aa.user === user.id)
    const columnsData = map(activitiesDurationByProject, (activityByProject, rowLabel) => {
      const { index, projectInst, ...restActivities } = activityByProject

      return {
        index,
        id: `${user.id}-${index}-${projectInst?.id || ''}`,
        rowLabel,
        project: projectInst?.id || null,
        projectInst,
        user: user.id,
        userInst: user,
        activities: calculateActivities(restActivities, projectInst),
        activitiesObjects: activitiesByProject2[rowLabel],
        leaves: reduxLeaves.filter((el) => el.user === user.id),
        // activitiesWithoutLeaves: filter(activities, activity => activity.activityType !== 'lv').reduce((acc, actvt) => {
        // return { ...acc, [actvt.date]: { ...(acc[actvt.date] || {}), duration: 14400, totalHours: 2 } }
        // }, {}),
        allActivities: reduce(
          activities2,
          (acc, actvt) => ({ ...acc, [actvt.date]: [...(acc[actvt.date] || []), actvt] }),
          {},
        ),
        activityApprovals,
        activityApprovalsByDate: getActivityApprovalsByDate(activityApprovals),
      }
    })

    return sortBy(columnsData, ['index', 'projectInst.name'])
  }

  function updateTable(updateType) {
    if (!tableInstance?.current) return
    const instance = tableInstance.current
    const { updateCells, reloadStats, getLastestSelection } = instance
    updateCells(
      updateType === 'leave'
        ? getLastestSelection?.().selectedCells
        : currentSelectedCells.current || getLastestSelection?.().selectedCells,
    )
    reloadStats()
  }

  const getLeaveDayPart = (date, leave) => {
    if (!leave) return null
    const formattedDate = moment(date).format('YYYY-MM-DD')
    const formattedLeaveStartDate = moment(leave?.startDate).format('YYYY-MM-DD')
    const leaveStartHour = moment.utc(leave?.startDate).format('HH')
    const formattedLeaveEndDate = moment(leave?.endDate).format('YYYY-MM-DD')
    const leaveEndHour = moment.utc(leave?.endDate).format('HH')
    if (formattedDate === formattedLeaveEndDate && leaveEndHour < 18) return 'morning'
    if (formattedDate === formattedLeaveStartDate && leaveStartHour > 8) return 'afternoon'
    return 'fullday'
  }

  function dayActions(instance, cell) {
    if (!cell.row.pivot) return []
    if (!permission(['human resources_time recap__Activity approvals'])) return []

    const cellValue: CellValue = cell.value
    const { id: date } = cell.column
    const { user: userId } = cell.row.original
    const { activityApprovals, leaves } = cellValue
    const dayLeave = leaves.find(({ startDate, endDate }) => {
      const sd = moment(startDate).format('MM/DD/YYYY')
      const ed = moment(endDate).format('MM/DD/YYYY')
      return moment(date).isBetween(sd, ed, 'day', '[]')
    })
    const { selectedCells } = instance.getLastestSelection()

    const momentDate = moment(date, 'YYYY-MM-DD')
    const startOfMonth = moment(momentDate).startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment(momentDate).endOf('month').format('YYYY-MM-DD')
    const startOfWeek = moment(momentDate).startOf('week').format('YYYY-MM-DD')
    const endOfWeek = moment(momentDate).endOf('week').format('YYYY-MM-DD')

    let activityApprovalWeek
    let activityApprovalMonth

    activityApprovals.forEach((aa) => {
      if (aa.startDate === startOfWeek && aa.endDate === endOfWeek) {
        activityApprovalWeek = aa
      }
      if (aa.startDate === startOfMonth && aa.endDate === endOfMonth) {
        activityApprovalMonth = aa
      }
    })

    function openLeaveModal(duration: number, dayPart: string = '', period: boolean = false) {
      return openModal(
        <ModalAddAbsenceActivity
          userId={userId}
          preSelectedDate={selectedCells && Object.keys(selectedCells).length === 1 ? momentDate.toDate() : undefined}
          duration={duration}
          dayPart={dayPart}
          preSelectedCells={selectedCells}
          tableTime={true}
          dayLeave={dayLeave}
          period={period}
        />,
      )
    }

    function checkForLeaveOnCell() {
      const leave = reduxLeaves.find(({ startDate, endDate, user }) => {
        const sd = moment(startDate).format('MM/DD/YYYY')
        const ed = moment(endDate).format('MM/DD/YYYY')
        return moment(date).isBetween(sd, ed, 'day', '[]') && userId === user
      })
      return !leave
    }

    async function onRemoveSeveralLeaves() {
      try {
        const formatedData = map(selectedCells, (cell) => {
          return {
            date: moment(cell.id, 'YYYY MM DD').format('YYYY-MM-DD'),
            userId: cell.value.user,
          }
        })
        const leavesToDelete = formatedData.map((element) => {
          const selectedUserLeaves = reduxLeaves.filter(
            (leave) =>
              leave.user === element.userId &&
              moment(element.date).isBetween(leave.startDate, leave.endDate, 'day', '[]'),
          )
          return selectedUserLeaves
        })
        const leavesIds = leavesToDelete?.flat().map((leave) => leave.id)
        return leavesToDelete?.flat().length && dispatch(deleteLeave(leavesIds))
      } catch (err) {
        error('Operation failed')
      }
    }

    async function onRemoveLeave(customText) {
      try {
        const leave = reduxLeaves.find((leave) => leave.id === dayLeave?.id)
        await dispatch(deleteLeave(leave?.id))
        success(customText ?? 'Leave deleted')
      } catch (err) {
        error('Operation failed')
      }
    }

    async function onValidateSeveralLeaves(selectedCells) {
      try {
        const formatedData = map(selectedCells, (cell) => {
          return {
            date: moment(cell.id, 'YYYY MM DD').format('YYYY-MM-DD'),
            userId: cell.value.user,
          }
        })
        const leavesToDelete = formatedData.map((element) => {
          const selectedUserLeaves = reduxLeaves.filter(
            (leave) =>
              leave.user === element.userId &&
              moment(element.date).isBetween(leave.startDate, leave.endDate, 'day', '[]'),
          )
          return selectedUserLeaves
        })
        const leaves = leavesToDelete?.flat().map((leave) => leave)
        return (
          leavesToDelete?.flat().length && dispatch(updateLeave(leaves.map((lv) => ({ ...lv, approvalStatus: 1 }))))
        )
      } catch (err) {
        error('Operation failed')
      }
    }

    async function onValidateLeave(leave) {
      try {
        await dispatch(updateLeave({ ...leave, approvalStatus: 1 }))
        success('Leave validated')
      } catch (err) {
        error('Operation failed')
      }
    }

    const actions = [
      {
        label: `Quick validation`,
        editAction: true,
        items: [
          {
            label: 'Validate week',
            onClick: (event: SyntheticMouseEvent<>) => quickValidate(cell, instance, 'week', true),
            editAction: true,
            hotKeys: ['w'],
            rightLabel: 'W',
          },
          {
            label: 'Unvalidate week',
            onClick: (event: SyntheticMouseEvent<>) => quickValidate(cell, instance, 'week', false),
            editAction: true,
            hotKeys: ['shift+w'],
            rightLabel: 'Shift + W',
          },
          'separator',
          {
            label: 'Validate month',
            onClick: (event: SyntheticMouseEvent<>) => quickValidate(cell, instance, 'month', true),
            editAction: true,
            hotKeys: ['m'],
            rightLabel: 'M',
          },
          {
            label: 'Unvalidate month',
            onClick: (event: SyntheticMouseEvent<>) => quickValidate(cell, instance, 'month', false),
            editAction: true,
            hotKeys: ['shift+m'],
            rightLabel: 'Shift + M',
          },
        ],
      },
      'separator',
      {
        label: `Custom validation`,
        editAction: true,
        items: [
          {
            label: `${activityApprovalWeek ? 'Update' : 'Add a validation for the'} week`,
            onClick: (event: SyntheticMouseEvent<>) => validate(cell, instance, 'week', activityApprovalWeek),
            editAction: true,
          },
          {
            label: `${activityApprovalMonth ? 'Update' : 'Add a validation for the'} month`,
            onClick: (event: SyntheticMouseEvent<>) => validate(cell, instance, 'month', activityApprovalMonth),
            editAction: true,
          },
        ],
      },
      'separator',
      {
        label: `Remove week validation`,
        onClick: (event: SyntheticMouseEvent<>) => removeValidation(cell, instance, 'week'),
        disabled: !activityApprovalWeek,
        editAction: true,
        hotKeys: ['delete'],
        rightLabel: 'Delete',
      },
      {
        label: `Remove month validation`,
        onClick: (event: SyntheticMouseEvent<>) => removeValidation(cell, instance, 'month'),
        disabled: !activityApprovalMonth,
        editAction: true,
        hotKeys: ['shift+delete'],
        rightLabel: 'Shift + Delete',
      },
      'separator',
      {
        label: 'Add a leave',
        editAction: true,
        items:
          selectedCells && Object.keys(selectedCells).length < 2
            ? [
                {
                  label: 'Morning',
                  editAction: true,
                  onClick: () => openLeaveModal(60 * 60 * 4, 'morning'),
                },
                {
                  label: 'Afternoon',
                  editAction: true,
                  onClick: () => openLeaveModal(60 * 60 * 4, 'afternoon'),
                },
                {
                  label: 'Full day',
                  editAction: true,
                  onClick: () => openLeaveModal(60 * 60 * 8, ''),
                },
              ]
            : [
                {
                  label: 'Period',
                  editAction: true,
                  onClick: () => openLeaveModal(60 * 60 * 8, '', true),
                },
              ],
      },
      'separator',
      {
        label: selectedCells && Object.keys(selectedCells).length < 2 ? 'Delete leave' : 'Delete leaves',
        disabled: checkForLeaveOnCell,
        editAction: true,
        onClick:
          selectedCells && Object.keys(selectedCells).length < 2
            ? async () => {
                const promises = [onRemoveLeave('Leave deleted'), removeValidation(cell, instance, 'day')]
                await Promise.all(promises)
              }
            : () =>
                confirmDelete({
                  title: `Delete several leaves`,
                  onValidate: async () => {
                    const promises = [onRemoveSeveralLeaves(), removeValidation(cell, instance, 'day')]
                    await Promise.all(promises)
                  },
                  render: `Are you sure you want to delete these leaves`,
                  validateMessage: 'Leaves deleted',
                }),
      },
      {
        label: selectedCells && Object.keys(selectedCells).length < 2 ? 'Validate leave' : 'Validate leaves',
        disabled: !dayLeave || dayLeave?.approvalStatus === 1,
        editAction: true,
        // onClick: () => onValidateLeave(selectedCells),
        onClick: () =>
          selectedCells && Object.keys(selectedCells).length < 2
            ? onValidateLeave(dayLeave)
            : onValidateSeveralLeaves(selectedCells),
      },
      {
        label: selectedCells && Object.keys(selectedCells).length < 2 ? 'Refuse leave' : 'Refuse leaves',
        disabled: checkForLeaveOnCell,
        editAction: true,
        onClick:
          selectedCells && Object.keys(selectedCells).length < 2
            ? async () => {
                const promises = [onRemoveLeave('Leave deleted'), removeValidation(cell, instance, 'day')]
                await Promise.allSettled(promises)
              }
            : () =>
                confirmDelete({
                  title: `Refuse several leaves`,
                  onValidate: async () => {
                    const promises = [onRemoveSeveralLeaves(), removeValidation(cell, instance, 'day')]
                    await Promise.allSettled(promises)
                  },
                  render: 'Are you sure you want to refuse this leaves ?',
                  validateMessage: 'Leaves refused',
                }),
      },
      'separator',
    ]

    if (!allotment) {
      actions.push(
        'separator',
        fillBusinessDayOption(instance, cell, projects, currentSelectedCells, reloadActivities, updateTable),
      )
    }
    return actions
  }

  const columns: Array<ColumnHeader> = useMemo(() => {
    return [
      {
        Header: ' ',
        id: 'users',
        columns: [
          CellText({
            id: 'user',
            Header: 'User',
            accessor: 'userInst.name',
            actions: () => [],
            fixable: true,
            fixed: 'left',
            readOnly: true,
            Pivoted: () => null,
            RenderRead: (cell, value: string) => {
              const { row } = cell
              if (row.depth !== 0) return null
              return (
                <div className="flex alignCenter fullHeight padding5 fullWidth">
                  <div style={{ maxWidth: '100%' }}>
                    <div className="ellipsis textNoWrap overflowHidden fontSize13 bold capitalize">{value}</div>
                  </div>
                </div>
              )
            },
            Total: (instance) => <div style={{ height: '20px', width: '100%' }} />,
          }),
          CellText({
            Header: 'Project',
            id: 'project',
            fixable: true,
            fixed: 'left',
            accessor: 'rowLabel',
            RenderRead: (cell, value: string, colored, isRowExpanded) => {
              const { row } = cell
              const [projectName, subTitle] = value.split('\n')

              return (
                <div
                  className="fullHeight fullWidth flex center alignCenter column fontSize13"
                  style={{ fontWeight: !row.pivotSubRow ? 'bold' : undefined }}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <div style={{ maxWidth: '100%' }}>
                    <div className="ellipsis textNoWrap overflowHidden">{projectName}</div>
                  </div>
                  {subTitle && !isRowExpanded ? (
                    <div style={{ maxWidth: '100%' }}>
                      <div style={{ color: 'grey', fontWeight: 'normal' }}>{subTitle}</div>
                    </div>
                  ) : null}
                </div>
              )
            },
            Total: (instance) => <div style={{ height: '20px', width: '100%' }} />,
            actions: () => [],
            readOnly: true,
          }),
        ],
      },
      {
        Header: startDate.format('MMMM YYYY'),
        id: 'days',
        columns: [
          ...map(days, (dayObject: Day, dateStr: string) => {
            const { date, dd, D, isCurrentDay, isWeekend } = dayObject
            const dateString = date.format('YYYY-MM-DD')
            return {
              Header: '',
              headerStyle: {
                padding: 5,
                width: '100%',
                color: isCurrentDay ? '#ffffff' : undefined,
                fontWeight: isCurrentDay ? 'bold' : undefined,
                backgroundColor: isCurrentDay ? 'rgba(0,138,230,0.6)' : undefined,
                boxShadow: isWeekend && !isCurrentDay ? '0 0 0 40px rgba(0,0,0,0.04) inset' : undefined,
              },
              headerSubLabel: (
                <span className="flex column alignCenter">
                  <span className="fontSize12">{dd}</span>
                  <span className="bold">{D}</span>
                </span>
              ),
              id: dateStr,
              accessor: (_) => _,
              actions: dayActions,
              cellType: 'decimalHours',
              onDoubleClickSelectCell: onSelectCell,
              readOnly: false,
              hiddenable: false,
              resizable: false,
              fixable: false,
              draggable: false,
              Cell: (instance) => {
                const { cell, visibleColumns, rows, leftColumns, rightColumns } = instance
                const { row, getCellProps, column } = cell
                const { isRowExpanded, ...cellPropsRest } = getCellProps()

                const cellStyle = getCellStyles(instance, cell, { isRowExpanded, ...cellPropsRest })
                const cellValue: CellValue = cell.value
                const leaves = cellValue.leaves
                // REDUX
                const activities = cellValue.activities?.[dateStr] || {}
                const activityApprovals = cellValue.activityApprovalsByDate?.[dateStr] || []
                const activitiesObjects = cellValue.activitiesObjects?.[dateStr] || []
                const dayLeave = leaves.find(({ startDate, endDate }) => {
                  const sd = moment(startDate).format('MM/DD/YYYY')
                  const ed = moment(endDate).format('MM/DD/YYYY')
                  return moment(date).isBetween(sd, ed, 'day', '[]')
                })
                const dayPart = getLeaveDayPart(dateString, dayLeave)
                const leaveIsPending = dayLeave?.approvalStatus === 0
                const { totalHours, totalHoursLimited, getProjectHours } = activities
                const { projectHoursLimited, projectTotalHours } = getProjectHours?.(row.subRows || []) || {}
                const { selectedCells } = instance.getLastestSelection()

                const comments = []
                activityApprovals?.forEach((aa, index) => {
                  if (aa.comment)
                    comments.push(
                      <TextRead key={aa.id} text={aa.comment} className={index > 0 ? 'marginTop5' : undefined} />,
                    )
                })

                const tooltipIsActive = activitiesObjects?.length || (row.pivot && comments.length)

                const colIndex = visibleColumns.findIndex((col) => col.id === column.id) + leftColumns.length
                const colLength = visibleColumns.length - rightColumns.length
                const rowIndex = row.rowListIndex
                const rowLength = rows.length

                if (
                  !currentSelectedCells.current &&
                  selectedUser === cellValue.user &&
                  String(selectedDay?.day()) === D
                ) {
                  currentSelectedCells.current = selectedCells
                }

                return (
                  <div
                    className={cx(classes.cellDay, { [classes.isWeekend]: isWeekend })}
                    style={cellStyle}
                    user-selector={cellValue.user}
                    day-selector={String(D)}
                    row-selector={cellValue.rowLabel}
                  >
                    {dayLeave && (
                      <div
                        className={cx(
                          dayPart === 'morning' && classes.morningOff,
                          dayPart === 'afternoon' && classes.afternoonOff,
                          dayPart === 'fullday' && classes.fullDayOff,
                          dayLeave && !leaveIsPending ? classes.leaveApproved : null,
                        )}
                      />
                    )}
                    {dayLeave && leaveIsPending ? (
                      <FontIcon className={classes.hourglassIcon} icon="far-hourglass" />
                    ) : null}
                    {comments.length > 0 ? <div className={classes.haveAnActivityApprovalsComment} /> : null}
                    {(isNumber(projectHoursLimited) || row.pivot) && selectedProject ? (
                      <span
                        className={cx(selectedProject ? 'bold' : null, {
                          [classes.warning]: (projectHoursLimited || 0) < projectTotalHours,
                        })}
                      >
                        {isNumber(projectHoursLimited) && projectHoursLimited !== 0 ? (
                          projectHoursLimited
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </span>
                    ) : null}
                    {!isRowExpanded || !isNumber(projectHoursLimited) ? (
                      <span
                        className={cx({
                          [classes.warning]:
                            ((row.depth || 0) > 0 || !selectedProject) && totalHoursLimited < totalHours,
                          [classes.isTotal]: selectedProject && row.pivot,
                          // [classes.isLeaveOnCell]: dayLeave,
                        })}
                      >
                        {totalHoursLimited || null}
                      </span>
                    ) : null}
                    {showQuickLook ? (
                      <div className={classes.quickView}>
                        {tooltipIsActive ? (
                          <div
                            className={cx(classes.tooltip, {
                              [classes.tooltipBottom]:
                                !(colIndex + 2 >= colLength && colIndex <= 2 && rowIndex + 10 >= rowLength) ||
                                rowIndex < 10,
                              [classes.tooltipLeft]: colIndex + 2 >= colLength,
                              [classes.tooltipRight]: colIndex <= 2,
                              [classes.tooltipTop]: rowIndex + 10 >= rowLength && rowIndex > 10,
                            })}
                          >
                            {comments.length ? <div className="flex column marginBottom10">{comments}</div> : null}
                            {activitiesObjects?.length ? (
                              <TableQuickLook activities={activitiesObjects} projects={projects} />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                )
              },
              Total: (instance) => {
                const { stats } = instance
                if (!stats || !stats.default) return null
                const dateNumber = date.dayOfYear()
                const day = stats.default.find((day) => day.day === dateNumber)
                if (!day) return null
                return (
                  <Tooltip title={<span className="bold">{Math.round((day.totalSupDay / 60 / 60) * 10) / 10}</span>}>
                    <div className="flex column alignCenter">
                      <span className="bold">{Math.round((day.totalSupDay / 60 / 60) * 10) / 10}</span>
                    </div>
                  </Tooltip>
                )
              },
              minWidth: 50,
              width: 70,
            }
          }),
        ],
      },
      {
        Header: 'Total',
        id: 'total',
        columns: [
          CellText({
            Header: 'Real',
            id: 'total_real',
            accessor: (item) => item,
            actions: () => [],
            readOnly: true,
            fixed: 'right',
            width: 60,
            RenderRead: (cell) => {
              const { getCellProps, row } = cell
              const { isRowExpanded } = getCellProps()
              const { hours, projectHours } = getTotalHours(cell)

              return (
                <div className="flex column center alignCenter fullHeight" onContextMenu={(e) => e.preventDefault()}>
                  {isNumber(projectHours) ? <div className="flex column alignCenter bold">{projectHours}</div> : null}
                  {!isRowExpanded || !isNumber(projectHours) ? (
                    <div
                      className={cx('flex column alignCenter', !selectedProject && row.pivot ? 'bold' : null, {
                        [classes.isTotal]: selectedProject && row.pivot,
                      })}
                    >
                      {hours}
                    </div>
                  ) : null}
                </div>
              )
            },
            Total: (instance) => {
              const { stats } = instance
              if (!stats || !stats.default) return null

              const total = stats.default.reduce((acc, day) => acc + day.totalSupDay, 0)

              return (
                <Tooltip title={<span className="bold">{Math.round((total / 60 / 60) * 10) / 10}</span>}>
                  <div className="flex column alignCenter">
                    <span className="bold">{Math.round((total / 60 / 60) * 10) / 10}</span>
                  </div>
                </Tooltip>
              )
            },
          }),
          CellText({
            Header: 'Prod',
            id: 'total_prod',
            accessor: (item) => item,
            actions: () => [],
            readOnly: true,
            width: 60,
            fixed: 'right',
            RenderRead: (cell) => {
              const { getCellProps, row } = cell
              const { isRowExpanded } = getCellProps()
              const { hours, projectHours } = getTotalHours(cell)

              return (
                <div className="flex column center alignCenter fullHeight" onContextMenu={(e) => e.preventDefault()}>
                  {isNumber(projectHours) ? (
                    <div className="flex column alignCenter bold">
                      {(projectHours || 0) < maxHoursWorkedInMonth ? projectHours : maxHoursWorkedInMonth}
                    </div>
                  ) : null}
                  {!isRowExpanded || !isNumber(projectHours) ? (
                    <div
                      className={cx('flex column alignCenter', !selectedProject && row.pivot ? 'bold' : null, {
                        [classes.isTotal]: selectedProject && row.pivot,
                      })}
                    >
                      {hours < maxHoursWorkedInMonth ? hours : maxHoursWorkedInMonth}
                    </div>
                  ) : null}
                </div>
              )
            },
          }),
          CellText({
            Header: 'Sup',
            id: 'total_sup',
            accessor: (item) => item,
            actions: () => [],
            readOnly: true,
            fixed: 'right',
            width: 60,
            RenderRead: (cell) => {
              const { getCellProps, row } = cell
              const { isRowExpanded } = getCellProps()
              const { hours, projectHours } = getTotalHours(cell)

              return (
                <div className="flex column center alignCenter fullHeight">
                  {isNumber(projectHours) && projectId !== 'all' && row.pivot ? (
                    <div className="flex column alignCenter bold">
                      {(projectHours || 0) <= maxHoursWorkedInMonth
                        ? 0
                        : Math.round(((projectHours || 0) - maxHoursWorkedInMonth) * 10) / 10}
                    </div>
                  ) : null}
                  {!isRowExpanded || !isNumber(projectHours) ? (
                    <div
                      className={cx('flex column alignCenter', !selectedProject && row.pivot ? 'bold' : null, {
                        [classes.isTotal]: selectedProject && row.pivot,
                      })}
                    >
                      {hours <= maxHoursWorkedInMonth ? 0 : Math.round((hours - maxHoursWorkedInMonth) * 10) / 10}
                    </div>
                  ) : null}
                </div>
              )
            },
          }),
        ],
      },
    ]
  }, [selectedRow, selectedUser, selectedDay, startDateString, endDateString, showQuickLook, reduxLeaves])

  useEffect(() => {
    getTools({ updateTable, getMetadataFromActivity })
  }, [tableInstance?.current])

  useEffect(() => {
    const $style = document.createElement('style')

    if (tableRef.current && selectedRow && selectedUser && selectedDay) {
      let rowSelector = selectedRow

      if (selectedRow === 'otherProjects') rowSelector = 'Other projects'
      else if (selectedRow === 'otherActivities') rowSelector = 'Other activities'
      else if (selectedRow === 'total') rowSelector = 'All projects'

      const selector = `${selectedUser ? `[user-selector="${selectedUser}"]` : ''}${
        // $FlowFixMe
        selectedDay?.format ? `[day-selector="${selectedDay.format('D')}"]` : ''
      }${rowSelector ? `[row-selector^="${rowSelector}"]` : ''}`

      const headerSelector = `${selectedUser ? `[user-selector="${selectedUser}"]` : ''}${
        // $FlowFixMe
        selectedDay?.format ? `[day-selector="${selectedDay.format('D')}"]` : ''
      }${rowSelector ? `[row-selector$="Total"]` : ''},
${selectedUser ? `[user-selector="${selectedUser}"]` : ''}${
        // $FlowFixMe
        selectedDay?.format ? `[day-selector="${selectedDay.format('D')}"]` : ''
      }${rowSelector ? `[row-selector="All projects"]` : ''}`

      $style.innerHTML = `
${selector} {
background-color: rgb(0, 138, 230) !important;
color: #ffffff;
font-weight: bold;
}
${selector} span {
background-color: transparent !important;
}
${headerSelector} {
border: 2px solid rgb(0, 138, 230);
}
`
      tableRef.current?.appendChild($style)
    }
    return () => $style.remove()
  }, [selectedRow, selectedUser, selectedDay, tableRef.current])

  const stateResources = reduce(
    allUsersWithActivities,
    (acc, row, id) => {
      const formatedData = formatRowData(row)
      if (Array.isArray(formatedData)) {
        formatedData.forEach((item) => {
          acc[item.id] = item
        })
        return acc
      }

      return { ...acc, [id]: formatedData }
    },
    {},
  )

  return (
    <div
      className="fullWidth fullHeight"
      ref={(ref) => {
        tableRef.current = ref
      }}
    >
      <TableRedux
        // key={userSearch?.id}
        tableId={tableId}
        getInstance={(instance) => {
          tableInstance.current = instance
        }}
        data={values(stateResources)}
        count={count}
        progress={httpProgress}
        extendToolbar={ExtendsToolbar}
        customFiltersToolbar={ToolbarFilters}
        columns={columns}
        pivotBy="user"
        stats={stats}
        statsIsLoading={statsIsLoading}
        loading={fetchLoading}
      />
    </div>
  )
}
