var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { reduce, map, sortBy, find, forEach, groupBy, flatMap } from 'lodash';
import history from 'app/main/routerHistory';
import { Table } from 'app/components/Table';
import { CellText, CellRichText, CellDuration, CellCheckbox, CellPriority, CellSelect, CellRating, CellAssetsSelect, CellPhone, ColumnTimeline, CellThumbnail, CellExternalJob, } from 'app/components/Table/Cells';
import resources from 'app/store/resources';
import { openModal, confirmDelete } from 'app/components/Modal';
import { ModalEditPerson } from 'app/containers/Modals/Persons/ModalEditPerson/ModalEditPerson';
import { getResources } from 'app/store/selectors';
import { getConfigEntryOption } from 'app/pages/Project/Settings/FollowUp/AttributesEditor/attributeAssets';
import { getContractTypeKey, getContractTypesOptions } from 'app/containers/Modals/Contracts/ModalEditContract/utils';
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings';
import { permission } from 'app/containers/Permissions';
import { getContractStatusOptions, getContractStatusColor } from 'app/core/utils/getContractsStatus';
import { getCategoriesPermissions } from 'app/core/utils/getCategoriesPermissions';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { TimelineCellComponent } from 'app/components/Table/Cells/ColumnTimeline/CellComponents/TimelineCellComponent';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import { MUIButton, Autocomplete } from 'app/components/Form';
import { ModalEditContract, } from 'app/containers/Modals/Contracts/ModalEditContract/ModalEditContract';
import { Filters } from './Filters';
import { formatRowPerson, formatRowContract } from './tablePeopleUtils';
import { MUIAutocompleteSelect } from '../../../components/Form/Autocomplete/MUIAutocompleteSelect.jsx';
import { useProgress } from '../../../hooks/useProgress';
import { contractTypesSelector } from 'app/store/selectors/serverConfig';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExternalJobs } from 'app/store/reducers/externalJobs';
import { externalJobsSelector } from 'app/store/selectors/externalJobs';
export const tableId = 'table-contracts';
export const TablePeople = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const { peopleSchemaId } = match.params;
    const [categories, setCategories] = useState({});
    const [contractAttributes, setContractAttributes] = useState({});
    const [projectPlans, setProjectPlans] = useState({});
    const [offices, setOffices] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [showGantt, setShowGantt] = useState(getUserSettings(`${tableId}-showGantt`));
    const [peopleSchemas, setPeopleSchemas] = useState();
    const [specificProject, setSpecificProject] = useState([{ label: 'All projects', value: 'all' }]);
    const [specificDepartment, setSpecificDepartment] = useState([{ label: 'All departments', value: 'all' }]);
    const [departments, setDepartments] = useState({});
    const [userFilters, setUserFilters] = useState([]);
    const [filterUnderContract, setFilterUnderContract] = useState(getUserSettings(`${tableId}-underContract`) || '');
    console.log('UDER', filterUnderContract);
    const externalJobs = useSelector(externalJobsSelector);
    const setFastFiltersFromFilters = () => {
        var _a, _b, _c, _d;
        const cleanedUserFilters = userFilters === null || userFilters === void 0 ? void 0 : userFilters.filter((uf) => ['Project', 'Department'].includes(uf[0].label));
        const selectedFilters = {
            project: [],
            department: [],
        };
        cleanedUserFilters.forEach((userFilter) => {
            const label = userFilter[0].label.toLowerCase();
            if (userFilter[1].type === 'operator' && userFilter[1].value === 'inList') {
                const inListFilterValues = userFilter === null || userFilter === void 0 ? void 0 : userFilter.filter((filt) => filt.type === 'value');
                selectedFilters[label].push(inListFilterValues);
            }
            if (userFilter[1].type === 'operator' && userFilter[1].value === 'notInList') {
                const options = userFilter[1].options.data;
                const notInListFilterValues = userFilter === null || userFilter === void 0 ? void 0 : userFilter.filter((filt) => filt.type === 'value');
                const filteredOptions = options.filter((option) => {
                    // @ts-ignore
                    return !notInListFilterValues.map((el) => el.value).includes(option.value);
                });
                selectedFilters[label].push(filteredOptions);
            }
        });
        setSpecificProject(((_a = selectedFilters.project) === null || _a === void 0 ? void 0 : _a.length) < 1
            ? [{ label: 'All projects', value: 'all' }]
            : [...new Set((_b = selectedFilters.project) === null || _b === void 0 ? void 0 : _b.flat())]);
        setSpecificDepartment(((_c = selectedFilters.department) === null || _c === void 0 ? void 0 : _c.length) < 1
            ? [{ label: 'All departments', value: 'all' }]
            : [...new Set((_d = selectedFilters.department) === null || _d === void 0 ? void 0 : _d.flat())]);
    };
    useEffect(() => {
        setFastFiltersFromFilters();
    }, [userFilters]);
    const tablePrefs = useRef();
    const contractTypes = useSelector(contractTypesSelector);
    const contractTypesOptions = getContractTypesOptions(contractTypes);
    const contractStatus = useMemo(() => getContractStatusOptions(), []);
    const canEditContracts = useMemo(() => permission(['human resources_organization_contracts_edit contracts']), []);
    const canEditPersons = useMemo(() => permission(['human resources_organization_contracts_edit persons informations']), []);
    const categoriesPermissions = useMemo(() => getCategoriesPermissions(), []);
    const now = useMemo(() => moment().format('YYYY-MM-DD'), []);
    const peopleSchema = useMemo(() => find(peopleSchemas, (ps) => ps.id === peopleSchemaId), [peopleSchemaId, peopleSchemas]);
    const { updateProgress } = useProgress();
    useEffect(() => {
        documentTitle('Contracts table', 'page');
        const config2 = (params, queries) => ({
            params: Object.assign(Object.assign({}, params), { queries: Object.assign({ page_size: 1000 }, queries) }),
        });
        const config = { params: { queries: { page_size: 1000 } } };
        const params = (progressKey) => ({
            getHttpProgress: (httpProgress) => updateProgress(progressKey, httpProgress),
        });
        dispatch(fetchExternalJobs());
        Promise.all([
            resources.categories.fetchAll(config),
            resources.contractAttributes.fetchAll(config),
            resources.departments.fetchAll(config2(params('departments'))),
            resources.projectPlans.fetchAll(config),
            resources.assets.fetchAll({ params: { queries: { page_size: 1000, assetType: 'of' } } }).then((res) => {
                setOffices(res.resources);
            }),
            resources.peopleSchemas.fetchAll(config),
        ]).then(() => {
            setCategories(getResources(undefined, 'categories', undefined, ['personAttributesInst']));
            setContractAttributes(getResources(undefined, 'contractAttributes'));
            setDepartments(getResources(undefined, 'departments'));
            setProjectPlans(getResources(undefined, 'projectPlans'));
            setDataLoaded(true);
            setPeopleSchemas(getResources(undefined, 'peopleSchemas'));
        });
    }, []);
    function formatRowData(person) {
        const formatedData = {};
        const { contractsInst } = person;
        formatedData[person.id] = formatRowPerson(person, {});
        sortBy(contractsInst, ['startDate', 'endDate'])
            .reverse()
            .forEach((contract) => {
            if (!formatedData[person.id].subRows[contract.id]) {
                formatedData[person.id].subRows[contract.id] = formatRowContract(contract, person.id);
            }
        });
        const _formatedData = map(formatedData, (depth0) => (Object.assign(Object.assign({}, depth0), { subRows: depth0.subRows ? map(depth0.subRows) : undefined })));
        return _formatedData;
    }
    function onRowsLengthChange(instance) {
        const { updateTable } = instance;
        updateTable();
    }
    function editPerson(e, instance, cell, person, type, modalParams) {
        const { updateOriginals } = instance;
        openModal(_jsx(ModalEditPerson, Object.assign({ person: person, categories: categories, onChange: (data) => {
                const newPerson = formatRowPerson(Object.assign(Object.assign({}, ((cell === null || cell === void 0 ? void 0 : cell.row.original) || {})), data), []);
                const rowId = String(cell ? cell.row.id : 'root');
                updateOriginals === null || updateOriginals === void 0 ? void 0 : updateOriginals({ [rowId]: newPerson }, type);
                onRowsLengthChange(instance);
            } }, modalParams)));
    }
    function getCurrentContract(originals) {
        return originals.find((original) => {
            if ((original === null || original === void 0 ? void 0 : original.startDate) && (original === null || original === void 0 ? void 0 : original.endDate)) {
                return original.startDate <= now && original.endDate >= now;
            }
            if ((original === null || original === void 0 ? void 0 : original.startDate) && !(original === null || original === void 0 ? void 0 : original.endDate)) {
                return original.startDate <= now;
            }
        });
    }
    function editContract(e, instance, cell, contract, type, modalParams) {
        const { updateOriginals } = instance;
        openModal(_jsx(ModalEditContract, Object.assign({ contract: contract, onChange: (data) => {
                const newContract = formatRowContract(Object.assign(Object.assign({}, (contract || {})), data), data.person);
                updateOriginals === null || updateOriginals === void 0 ? void 0 : updateOriginals({ [cell.row.id]: newContract }, type);
                onRowsLengthChange(instance);
            } }, modalParams)));
    }
    function actions(instance, cell) {
        const deleteAction = (label) => ({
            label,
            editAction: true,
            rightLabel: 'Shift + Delete',
            hotKeys: ['shift+delete', 'shift+suppr'],
            onClick: (e) => {
                confirmDelete({
                    render: `Are you sure you want to delete ${cell.row.original.name} ?`,
                    onValidate: () => resources[cell.row.original.__resource].delete(cell.row.original.id).then(() => {
                        var _a;
                        (_a = instance.updateOriginals) === null || _a === void 0 ? void 0 : _a.call(instance, { [cell.row.id]: null }, 'delete');
                    }),
                });
            },
        });
        if (cell.row.original.__resource === 'people') {
            const personsActions = [];
            if (canEditPersons) {
                personsActions.push({
                    label: 'Edit person',
                    editAction: true,
                    rightLabel: 'Shift + P',
                    hotKeys: ['shift+p'],
                    onClick: (e) => {
                        const _a = cell.row.original, { subRows } = _a, person = __rest(_a, ["subRows"]);
                        editPerson(e, instance, cell, person);
                    },
                }, deleteAction('Delete person'));
            }
            if (canEditContracts) {
                personsActions.push('separator', {
                    label: 'Add a new contract',
                    editAction: true,
                    rightLabel: 'Shift + N',
                    hotKeys: ['shift+n'],
                    onClick: (e) => {
                        const _a = cell.row.original, { subRows } = _a, person = __rest(_a, ["subRows"]);
                        editContract(e, instance, cell, undefined, 'create', { parentSubRows: subRows, person });
                    },
                });
            }
            return personsActions;
        }
        if (cell.row.original.__resource === 'contracts') {
            if (['signed', 'signing'].includes(cell.row.original.contractStatus))
                return [];
            if (canEditContracts) {
                return [
                    {
                        label: 'Edit contract',
                        editAction: true,
                        rightLabel: 'Shift + E',
                        hotKeys: ['shift+e'],
                        onClick: (e) => {
                            const _a = cell.row.original, { subRows } = _a, contract = __rest(_a, ["subRows"]);
                            editContract(e, instance, cell, contract, undefined, { parentSubRows: subRows });
                        },
                    },
                    deleteAction('Delete contract'),
                ];
            }
        }
        return [];
    }
    function getAttributesColumns(attribute, 
    // TODO: remove any and check for correct type Partial<DefaultCellProps>
    params) {
        const { editorType, editorParams, attrType } = attribute;
        if (editorType === 'choice' || attrType === 'skill') {
            const { choice = [] } = editorParams;
            let customChoiceList;
            if (attrType === 'skill')
                customChoiceList = getConfigEntryOption('SKILL_LEVELS');
            return CellSelect(Object.assign(Object.assign({}, params), { options: () => customChoiceList ||
                    (!editorParams.userOrdering
                        ? sortBy(choice.map((c) => ({ value: c, label: c })), ['label'])
                        : choice.map((c) => ({ value: c, label: c }))) }));
        }
        switch (attrType) {
            case 'integer':
            case 'float':
            case 'char':
            case 'datetime':
            case 'time':
            case 'date':
            case 'email':
            case 'url': {
                let type = 'text';
                if (editorType === 'int')
                    type = 'number';
                if (editorType === 'float')
                    type = 'number';
                if (editorType === 'str')
                    type = 'text';
                if (editorType === 'datetime')
                    type = 'datetime';
                if (editorType === 'time')
                    type = 'time';
                if (editorType === 'date')
                    type = 'date';
                if (editorType === 'email')
                    type = 'email';
                if (editorType === 'url')
                    type = 'url';
                if (editorType === 'slider')
                    type = 'number';
                if (editorType === 'iban')
                    type = 'text';
                if (editorType === 'ssn')
                    type = 'text';
                if (editorType === 'rating') {
                    return CellRating(params);
                }
                if (editorType === 'phone') {
                    return CellPhone(Object.assign({}, params));
                }
                if (editorType === 'priority') {
                    return CellPriority(Object.assign({}, params));
                }
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type, editorType } }));
            }
            case 'phone': {
                return CellPhone(Object.assign({}, params));
            }
            case 'text': {
                return CellRichText(Object.assign({}, params));
            }
            case 'duration': {
                return CellDuration(Object.assign({}, params));
            }
            case 'bool': {
                return CellCheckbox(Object.assign({}, params));
            }
            default:
                return CellText(Object.assign({}, params));
        }
    }
    function getPersonInformationsColumn(data, settings, defaultColumnSettings) {
        var _a;
        const { personInformation } = data;
        const { type } = personInformation;
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditPersons || ((_a = settings.readOnly) !== null && _a !== void 0 ? _a : false) });
        switch (type) {
            case 'firstname': {
                return CellText(Object.assign(Object.assign({}, params), { accessor: 'firstName', sortingKey: 'firstName', searchFilterLabel: 'First name', actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                firstName: value || null,
                            };
                        },
                    } }));
            }
            case 'lastname': {
                return CellText(Object.assign(Object.assign({}, params), { accessor: 'lastName', sortingKey: 'lastName', searchFilterLabel: 'Last name', actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                lastName: value || null,
                            };
                        },
                    } }));
            }
            case 'linkedUSer': {
                return CellAssetsSelect(Object.assign(Object.assign({}, params), { accessor: 'userInst', sortingKey: 'user__uuid', assetTypes: ['us'], searchFilterLabel: 'Linked user', placeholder: 'User', actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'past', 'delete', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                user: (value === null || value === void 0 ? void 0 : value.id) || null,
                            };
                        },
                    } }));
            }
            case 'jobs': {
                return CellRichText(Object.assign(Object.assign({}, params), { accessor: 'jobs', width: 300, customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    } }));
            }
            case 'externalJob1': {
                return CellExternalJob(Object.assign(Object.assign({}, params), { accessor: 'externalJob1', searchFilterLabel: 'External Job 1', customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'past', 'delete', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                externalJob1: value || null,
                            };
                        },
                    }, options: () => sortBy(map(externalJobs, (job) => ({ value: job.id, label: job.name })), ['label']) }));
            }
            case 'externalJob2': {
                return CellExternalJob(Object.assign(Object.assign({}, params), { accessor: 'externalJob2', searchFilterLabel: 'External Job 2', customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'past', 'delete', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                externalJob2: value || null,
                            };
                        },
                    }, options: () => sortBy(map(externalJobs, (job) => ({ value: job.id, label: job.name })), ['label']) }));
            }
            case 'lastJob': {
                return CellText(Object.assign(Object.assign({}, params), { accessor: 'lastJobs', searchFilterLabel: 'Last job at TeamTO', customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                    } }));
            }
            case 'underContract': {
                return CellSelect(Object.assign(Object.assign({}, params), { accessor: 'underContract', searchFilterLabel: 'Under Contract', noFilterOption: true, customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        const { underContractToday } = cell.row.original;
                        const isUnderContract = underContractToday;
                        return (_jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: isUnderContract ? 'ACTIF' : 'NON ACTIF' }));
                    }, options: () => [] }));
            }
            case 'thumbnail':
                return CellThumbnail(Object.assign(Object.assign({}, params), { accessor: (item) => {
                        var _a;
                        if (item === null || item === void 0 ? void 0 : item.thumbnailInst)
                            return item.thumbnailInst;
                        return (_a = item === null || item === void 0 ? void 0 : item.userInst) === null || _a === void 0 ? void 0 : _a.thumbnailInst;
                    }, sortingKey: 'thumbnailInst', searchFilterLabel: 'Thumbnail', actions: (instance, cell) => cell.row.original.__resource === 'people'
                        ? ['edit', 'copy', 'separator', ...actions(instance, cell)]
                        : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, save: {
                        resource: 'people',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                thumbnail: value.id,
                            };
                        },
                    } }));
            default:
                return null;
        }
    }
    function getMetadatasColumn(data, settings, defaultColumnSettings) {
        var _a;
        const { metadata } = data;
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditPersons || ((_a = settings.readOnly) !== null && _a !== void 0 ? _a : false) });
        switch (metadata.type) {
            case 'lastLogin':
                return CellText(Object.assign(Object.assign({}, params), { accessor: 'lastLogin', inputProps: {
                        type: 'datetime',
                    }, customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    }, actions: (instance, cell) => cell.row.original.__resource === 'people' ? actions(instance, cell) : actions(instance, cell) }));
            case 'createdAt':
                return CellText(Object.assign(Object.assign({}, params), { accessor: 'createdAt', searchFilterLabel: 'Created at', inputProps: {
                        type: 'datetime',
                    }, actions: (instance, cell) => cell.row.original.__resource === 'people' ? actions(instance, cell) : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    } }));
            case 'updatedAt':
                return CellText(Object.assign(Object.assign({}, params), { searchFilterLabel: 'Updated at', accessor: (item) => {
                        const maxPersonAttributesUpdate = reduce(item.attributesInst, (max, personAttribute) => (max >= personAttribute.updatedAt ? max : personAttribute.updatedAt), item.updatedAt);
                        const maxContractAttributesUpdate = reduce(item.contractsInst, (max, contractAttribute) => (max >= contractAttribute.updatedAt ? max : contractAttribute.updatedAt), item.updatedAt);
                        if (maxPersonAttributesUpdate && maxContractAttributesUpdate) {
                            return maxPersonAttributesUpdate > maxContractAttributesUpdate
                                ? maxPersonAttributesUpdate
                                : maxContractAttributesUpdate;
                        }
                        return maxPersonAttributesUpdate || maxContractAttributesUpdate;
                    }, inputProps: {
                        type: 'datetime',
                    }, actions: (instance, cell) => cell.row.original.__resource === 'people' ? actions(instance, cell) : actions(instance, cell), customRender: (instance, cell) => {
                        if (cell.row.original.__resource !== 'people') {
                            return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                        }
                        return undefined;
                    } }));
            default:
                return null;
        }
    }
    function getPersonAttributesColumn(data, settings, defaultColumnSettings) {
        var _a;
        const { personAttribute: columnPersonAttribute, category: columnCategory } = data;
        const category = categories[columnCategory.id];
        if (!category)
            return null;
        const personAttribute = find(category.personAttributesInst, (pa) => pa.id === columnPersonAttribute.id);
        if (!personAttribute)
            return null;
        const { readPermission, deletePermission, updatePermission } = categoriesPermissions[category.id];
        if (!readPermission)
            return null;
        const getPersonAttributeValue = (item) => { var _a; return (_a = item.attributesInst) === null || _a === void 0 ? void 0 : _a[personAttribute.id]; };
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditPersons || (!updatePermission && !deletePermission) || ((_a = settings.readOnly) !== null && _a !== void 0 ? _a : false), extends: { personAttribute }, sortingKey: `personAttribute_${personAttribute.id}`, customRender: (instance, cell) => {
                if (cell.row.original.__resource !== 'people') {
                    return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                }
                return undefined;
            }, accessor: (item) => { var _a; return (_a = getPersonAttributeValue(item)) === null || _a === void 0 ? void 0 : _a.value; }, actions: (instance, cell) => {
                if (cell.row.original.__resource === 'people') {
                    // TODO: fix type "any"
                    const _actions = [];
                    if (updatePermission)
                        _actions.push('edit', 'copy', 'past');
                    if (deletePermission)
                        _actions.push('delete');
                    return [..._actions, 'separator', ...actions(instance, cell)];
                }
                return actions(instance, cell);
            }, save: {
                resource: 'personAttributeValues',
                formatData: (original, value, cell, instance, type) => {
                    var _a, _b;
                    instance.edition.endEdit(cell);
                    if (type === 'delete' || (!value && value !== 0))
                        return (_a = getPersonAttributeValue(original)) === null || _a === void 0 ? void 0 : _a.id;
                    return {
                        id: (_b = getPersonAttributeValue(original)) === null || _b === void 0 ? void 0 : _b.id,
                        value: value || null,
                        personAttribute: personAttribute.id,
                        person: original.id,
                    };
                },
            } });
        return getAttributesColumns(personAttribute, params);
    }
    function getContractAttributesColumn(data, settings, defaultColumnSettings) {
        var _a;
        const { contractAttribute: columnContractAttribute } = data;
        const contractAttribute = contractAttributes[columnContractAttribute.id];
        if (!contractAttribute)
            return null;
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditContracts || ((_a = settings.readOnly) !== null && _a !== void 0 ? _a : false), accessor: `attributes.${contractAttribute.name}`, customRender: (instance, cell) => {
                if (cell.row.original.__resource !== 'contracts') {
                    return _jsx("div", { className: "grey flex center alignCenter fullHeight fullWidth", children: "-" });
                }
                return undefined;
            }, actions: (instance, cell) => {
                if (cell.row.original.__resource === 'contracts') {
                    return ['edit', 'delete', 'copy', 'past', 'separator', ...actions(instance, cell)];
                }
                return actions(instance, cell);
            }, save: {
                resource: 'contracts',
                formatData: (original, value, cell, instance, type) => {
                    instance.edition.endEdit(cell);
                    if (type === 'delete' || value === null) {
                        return {
                            id: original.id,
                            attributes: Object.assign(Object.assign({}, cell.row.original.attributes), { [contractAttribute.name]: null }),
                        };
                    }
                    return {
                        id: original.id,
                        attributes: Object.assign(Object.assign({}, cell.row.original.attributes), { [contractAttribute.name]: value || null }),
                    };
                },
            } });
        return getAttributesColumns(contractAttribute, params);
    }
    function getContractInformationsColumn(data, settings, defaultColumnSettings) {
        const { type } = data.contractInformation;
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditContracts });
        switch (type) {
            case 'contractType':
                return CellSelect(Object.assign(Object.assign({}, params), { searchFilterLabel: 'Contract type', options: () => contractTypesOptions, accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts')
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.contractType;
                        return item.contractType;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            if (['signed', 'signing'].includes(cell.row.original.contractStatus))
                                return ['copy', 'separator', ...actions(instance, cell)];
                            return ['edit', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, width: 100, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                contractType: value || null,
                            };
                        },
                    } }));
            case 'contractStatus':
                return CellSelect(Object.assign(Object.assign({}, params), { searchFilterLabel: 'Contract status', options: () => contractStatus, accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts')
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.contractStatus;
                        return item.contractStatus;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                contractStatus: value || null,
                            };
                        },
                    }, width: 100 }));
            case 'startDate':
                return CellText(Object.assign(Object.assign({}, params), { searchFilterLabel: 'Start date', inputProps: { type: 'date' }, accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts' && !row.isExpanded) {
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.startDate;
                        }
                        return item.startDate;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            if (['signed', 'signing'].includes(cell.row.original.contractStatus))
                                return ['copy', 'separator', ...actions(instance, cell)];
                            return ['edit', 'delete', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, width: 90, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            if (type === 'delete' || value === null) {
                                return {
                                    id: original.id,
                                    startDate: null,
                                };
                            }
                            return {
                                id: original.id,
                                startDate: value || null,
                            };
                        },
                    } }));
            case 'endDate':
                return CellText(Object.assign(Object.assign({}, params), { searchFilterLabel: 'End date', inputProps: { type: 'date' }, accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts')
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.endDate;
                        return item.endDate;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            if (['signed', 'signing'].includes(cell.row.original.contractStatus))
                                return ['copy', 'separator', ...actions(instance, cell)];
                            return ['edit', 'delete', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, width: 90, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            if (type === 'delete' || value === null) {
                                return {
                                    id: original.id,
                                    endDate: null,
                                };
                            }
                            return {
                                id: original.id,
                                endDate: value || null,
                            };
                        },
                    }, description: 'endDate' }));
            case 'missionEstimatedStartDate':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type: 'date' }, searchFilterLabel: 'Mission estimated start date', accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts')
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.missionEstimatedStartDate;
                        return item.missionEstimatedStartDate;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'delete', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, width: 90, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            if (type === 'delete' || value === null) {
                                return {
                                    id: original.id,
                                    missionEstimatedStartDate: null,
                                };
                            }
                            return {
                                id: original.id,
                                missionEstimatedStartDate: value || null,
                            };
                        },
                    } }));
            case 'missionEstimatedEndDate':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type: 'date' }, searchFilterLabel: 'Mission estimated end date', accessor: (item, rowIndex, row) => {
                        var _a;
                        if (item.__resource !== 'contracts')
                            return (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.missionEstimatedEndDate;
                        return item.missionEstimatedEndDate;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'delete', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            if (type === 'delete' || value === null) {
                                return {
                                    id: original.id,
                                    missionEstimatedEndDate: null,
                                };
                            }
                            return {
                                id: original.id,
                                missionEstimatedEndDate: value || null,
                            };
                        },
                    }, width: 90 }));
            case 'salary':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type: 'number' }, searchFilterLabel: 'Salary', accessor: (item, rowIndex, row) => {
                        var _a, _b, _c;
                        if (item.__resource !== 'contracts')
                            return (_b = (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.secureDataInst) === null || _b === void 0 ? void 0 : _b.salary;
                        return (_c = item === null || item === void 0 ? void 0 : item.secureDataInst) === null || _c === void 0 ? void 0 : _c.salary;
                    }, actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, readOnly: !canEditContracts, width: 50, save: {
                        resource: 'contractSecureDatas',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.secureData,
                                contract: original.id,
                                salary: value,
                            };
                        },
                    } }));
            case 'office':
                return CellSelect(Object.assign(Object.assign({}, params), { width: 200, searchFilterLabel: 'Office', options: () => offices.map((office) => {
                        const name = office.name[0].toUpperCase() + office.name.slice(1);
                        return { value: office.id, label: name };
                    }), actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, customRender: (instance, cell) => {
                        const office = offices.find((el) => { var _a; return el.id === ((_a = getCurrentContract(cell.row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.office); });
                        if (cell.row.original.__resource !== 'contracts') {
                            return _jsx("div", { className: "flex center alignCenter fullHeight fullWidth", children: office === null || office === void 0 ? void 0 : office.name });
                        }
                        return undefined;
                    }, accessor: (item, rowIndex, row) => {
                        const office = offices.find((off) => { var _a; return off.id === ((_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.office); });
                        if (item.__resource !== 'contracts') {
                            return office === null || office === void 0 ? void 0 : office.name;
                        }
                        return item === null || item === void 0 ? void 0 : item.office;
                    }, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            return {
                                id: original.id,
                                office: value || null,
                            };
                        },
                    } }));
            case 'projectPlan':
                return CellSelect(Object.assign(Object.assign({}, params), { searchFilterLabel: 'Project', width: 200, options: () => map(projectPlans, (pp) => ({ label: pp.name, value: pp.id })), actions: (instance, cell) => {
                        if (cell.row.original.__resource === 'contracts') {
                            return ['edit', 'copy', 'past', 'separator', ...actions(instance, cell)];
                        }
                        return actions(instance, cell);
                    }, customRender: (instance, cell) => {
                        const project = find(projectPlans, (pp) => { var _a; return pp.id === ((_a = getCurrentContract(cell.row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.projectPlan); });
                        if (cell.row.original.__resource !== 'contracts') {
                            return _jsx("div", { className: "flex center alignCenter fullHeight fullWidth", children: project === null || project === void 0 ? void 0 : project.name });
                        }
                        return undefined;
                    }, accessor: (item, rowIndex, row) => {
                        var _a, _b;
                        if (item.__resource !== 'contracts') {
                            return (_b = (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.projectPlanInst) === null || _b === void 0 ? void 0 : _b.name;
                        }
                        return item === null || item === void 0 ? void 0 : item.projectPlan;
                    }, save: {
                        resource: 'contracts',
                        formatData: (original, value, cell, instance, type) => {
                            if (type === 'delete' || value === null) {
                                return {
                                    id: original.id,
                                    projectPlan: null,
                                };
                            }
                            return {
                                id: original.id,
                                projectPlan: value || null,
                            };
                        },
                    } }));
            case 'contractsSummary':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: {}, accessor: (item, rowIndex, row) => {
                        if (item.__resource !== 'contracts') {
                            const sortedContracts = sortBy(row.originalSubRows, ['projectPlan', 'startDate']);
                            const filteredContracts = groupBy(sortedContracts, 'projectPlan');
                            forEach(filteredContracts, (contract) => {
                                const length = contract.length;
                                for (let i = 0; i < length; i++) {
                                    contract[i].numberOfContract = i + 1;
                                }
                                return contract;
                            });
                            const flattenedSubrows = flatMap(filteredContracts);
                            return getCurrentContract(flattenedSubrows);
                        }
                        return item;
                    }, readOnly: true, width: 450, description: 'contractsSummary' }));
            default:
                return null;
        }
    }
    function getCollectiveAgreementColumn(data, settings, defaultColumnSettings) {
        var _a;
        const { collectiveAgreement } = data;
        const params = Object.assign(Object.assign({}, defaultColumnSettings), { readOnly: !canEditContracts || ((_a = settings.readOnly) !== null && _a !== void 0 ? _a : false) });
        switch (collectiveAgreement.type) {
            case 'title':
                return CellText(Object.assign(Object.assign({}, params), { noFilterOption: true, accessor: (item, rowIndex, row) => {
                        var _a, _b, _c;
                        if (item.__resource !== 'contracts')
                            return (_b = (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.collectiveAgreementTitleData) === null || _b === void 0 ? void 0 : _b.jobTitle;
                        return (_c = item === null || item === void 0 ? void 0 : item.collectiveAgreementTitleData) === null || _c === void 0 ? void 0 : _c.jobTitle;
                    }, actions: (instance, cell) => actions(instance, cell) }));
            case 'minimumSalary':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type: 'number' }, accessor: (item, rowIndex, row) => {
                        var _a, _b, _c;
                        if (item.__resource !== 'contracts')
                            return (_b = (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.collectiveAgreementTitleData) === null || _b === void 0 ? void 0 : _b.minimumSalary;
                        return (_c = item === null || item === void 0 ? void 0 : item.collectiveAgreementTitleData) === null || _c === void 0 ? void 0 : _c.minimumSalary;
                    }, actions: (instance, cell) => actions(instance, cell), width: 150 }));
            case 'secondMinimumSalary':
                return CellText(Object.assign(Object.assign({}, params), { inputProps: { type: 'number' }, accessor: (item, rowIndex, row) => {
                        var _a, _b, _c;
                        if (item.__resource !== 'contracts')
                            return (_b = (_a = getCurrentContract(row.originalSubRows)) === null || _a === void 0 ? void 0 : _a.collectiveAgreementTitleData) === null || _b === void 0 ? void 0 : _b.secondMinimumSalary;
                        return (_c = item === null || item === void 0 ? void 0 : item.collectiveAgreementTitleData) === null || _c === void 0 ? void 0 : _c.secondMinimumSalary;
                    }, actions: (instance, cell) => actions(instance, cell), width: 150 }));
            default:
                return null;
        }
    }
    const columns = useMemo(() => {
        if (!peopleSchema)
            return [];
        const columns = [
            {
                id: 'expanders',
                Header: ' ',
                columns: [
                    {
                        id: 'treeExpander',
                        cellType: 'CellGanttTree',
                        isCustomPivot: true,
                        Header: ' ',
                        canGroupBy: false,
                        hiddenable: false,
                        unselectable: true,
                        fixable: false,
                        fixed: 'left',
                        readOnly: false,
                        accessor: '',
                        width: 35,
                        minWidth: 35,
                        Cell: (instance) => {
                            var _a, _b, _c;
                            const { row, cell } = instance;
                            const { onClick: expanderOnClick } = row.getToggleRowExpandedProps();
                            return row.subRows && ((_a = row.original) === null || _a === void 0 ? void 0 : _a.__subRowDepth) === 0 ? (_jsx(MUIButton, { disableRipple: true, disabled: ((_b = row.subRows) === null || _b === void 0 ? void 0 : _b.length) === 0, variant: "text", onClick: (e) => {
                                    var _a, _b;
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.preventDefault();
                                    if ((_a = row.subRows) === null || _a === void 0 ? void 0 : _a.length) {
                                        (_b = cell.update) === null || _b === void 0 ? void 0 : _b.call(cell);
                                        expanderOnClick === null || expanderOnClick === void 0 ? void 0 : expanderOnClick(e);
                                    }
                                }, style: { width: 30, height: '100%', color: '#4a4a4a' }, children: _jsx(FontIcon, { icon: row.isExpanded ? 'fas-caret-down' : 'fas-caret-right', className: ((_c = row.subRows) === null || _c === void 0 ? void 0 : _c.length) ? 'grey' : 'lightgrey' }) })) : null;
                        },
                    },
                ],
            },
            ...peopleSchema.schema.map((schemaGroup) => {
                const { items, key, name, settings } = schemaGroup;
                return {
                    id: key,
                    Header: name,
                    readOnly: settings.readOnly,
                    headerColor: settings.color,
                    columns: items
                        .map((schemaColumn) => {
                        var _a;
                        const { data, settings, key } = schemaColumn;
                        const defaultColumnSettings = {
                            id: key,
                            Header: settings.columnName || settings.name,
                            fixable: (_a = settings.fixable) !== null && _a !== void 0 ? _a : true,
                            hiddenable: settings.hiddenable,
                            fixed: settings.fixed,
                            hidden: settings.hidden,
                            hiddenByDefault: !settings.isVisible,
                            description: settings.description,
                            headerColor: settings.color,
                        };
                        switch (data.contentType) {
                            case 'personInformation':
                                return getPersonInformationsColumn(data, settings, defaultColumnSettings);
                            case 'personAttribute':
                                return getPersonAttributesColumn(data, settings, defaultColumnSettings);
                            case 'contractInformation':
                                return getContractInformationsColumn(data, settings, defaultColumnSettings);
                            case 'contractAttribute':
                                return getContractAttributesColumn(data, settings, defaultColumnSettings);
                            case 'metadata':
                                return getMetadatasColumn(data, settings, defaultColumnSettings);
                            case 'collectiveAgreement':
                                return getCollectiveAgreementColumn(data, settings, defaultColumnSettings);
                            default:
                                return null;
                        }
                    })
                        .filter(Boolean),
                };
            }),
        ];
        columns.push(ColumnTimeline({
            CellComponent: TimelineCellComponent,
            accessor: (item) => {
                if (item.__resource === 'contracts')
                    return [item];
                return item === null || item === void 0 ? void 0 : item.subRows;
            },
            save: {
                resource: 'contracts',
                saveData: (original, objectValue, cell, instance, type) => {
                    const { updateCells, resetGanttCell } = instance;
                    const { __resource, subRows, includedResources } = objectValue, value = __rest(objectValue, ["__resource", "subRows", "includedResources"]);
                    const { startDate, endDate, id } = value;
                    if (original.__resource !== 'contracts')
                        return Promise.resolve();
                    return resources.contracts.update({ id, startDate, endDate }).then(() => {
                        updateCells({ [cell.id]: cell });
                        resetGanttCell === null || resetGanttCell === void 0 ? void 0 : resetGanttCell(cell);
                    });
                },
            },
            timelineEdition: (item, instance) => {
                var _a;
                if (!item)
                    return undefined;
                const { row, state: { expanded }, } = instance;
                if (expanded[row.id])
                    return undefined;
                const color = getContractStatusColor(item.contractStatus);
                const { id, projectPlan, contractType, collectiveAgreementTitleData } = item;
                return {
                    id,
                    resource: item.contractStatus !== 'signed' ? 'contracts' : undefined,
                    color,
                    label: `${getContractTypeKey(contractType)} - ${(collectiveAgreementTitleData === null || collectiveAgreementTitleData === void 0 ? void 0 : collectiveAgreementTitleData.jobTitle) || ''} - ${((_a = projectPlans[projectPlan]) === null || _a === void 0 ? void 0 : _a.name) || ''}`,
                    start: {
                        position: 'start',
                        attr: 'startDate',
                        formatValue: (date) => date.format('YYYY-MM-DD'),
                    },
                    end: {
                        position: 'end',
                        attr: 'endDate',
                        formatValue: (date) => date.format('YYYY-MM-DD'),
                    },
                };
            },
        }));
        return columns;
    }, [categories, contractAttributes, externalJobs, projectPlans, peopleSchema, now]);
    const personAttributes = useMemo(() => reduce(categories, (acc, category) => (Object.assign(Object.assign({}, acc), category.personAttributesInst)), {}), []);
    function toggleButtons(instance) {
        // TODO: fix type "any"
        const buttons = [
            {
                key: 'showGantt',
                tooltip: 'Show timeline',
                icon: 'fas-stream',
                selected: showGantt,
                onClick: (e) => setShowGantt((sg) => {
                    setUserSettings(`${tableId}-showGantt`, !sg);
                    return !sg;
                }),
            },
        ];
        if (canEditPersons) {
            buttons.unshift({
                key: 'addPerson',
                tooltip: 'Add a person',
                icon: 'fas-user-plus',
                onClick: (e) => {
                    editPerson(e, instance, undefined, undefined, 'create');
                },
            });
        }
        return [buttons];
    }
    const dropdownButton = () => {
        return [
            {
                key: 'filterUnderContract',
                tooltip: 'Filter under contract',
                menuItem: [
                    { label: 'This month', value: 'thisMonth' },
                    { label: 'Today', value: 'today' },
                ],
                onClick: (val) => {
                    setFilterUnderContract(val);
                    setUserSettings(`${tableId}-underContract`, val);
                },
                filterUnderContract,
            },
        ];
    };
    function onChangeSchema({ value }, e) {
        history.pushWithParams(match, { peopleSchemaId: value });
    }
    const extendToolbar = (instance) => {
        function getOptions(list, label) {
            return [{ label: `All ${label}`, value: 'all' }].concat(sortBy(map(list, (item) => ({ label: item.name, value: item.id })), ['label']));
        }
        function getOnChange(onChange, label) {
            return (values) => {
                var _a;
                const allOpt = values.find((opt) => opt.value === 'all');
                if (values.length === 0)
                    return onChange([{ label: `All ${label}`, value: 'all' }]);
                if (((_a = values[values.length - 1]) === null || _a === void 0 ? void 0 : _a.value) === 'all')
                    return onChange([values[values.length - 1]]);
                if (allOpt && values.length > 1)
                    return onChange(values.filter((opt) => opt.value !== 'all'));
                return onChange(values);
            };
        }
        function setFastFilters(filterType, specificData) {
            var _a, _b;
            const { filtersTools } = ((_a = instance === null || instance === void 0 ? void 0 : instance.getLastestInstance) === null || _a === void 0 ? void 0 : _a.call(instance)) || instance;
            if (!filtersTools)
                return;
            const { setFilters, getLastestFilters } = filtersTools;
            const { columnsFilters } = filtersTools || {};
            const filters = getLastestFilters();
            const columnFilter = columnsFilters === null || columnsFilters === void 0 ? void 0 : columnsFilters.find((cf) => cf.label === filterType);
            if (columnFilter) {
                const eqFilter = (_b = columnFilter.operators) === null || _b === void 0 ? void 0 : _b.find((op) => ['inList'].includes(op.value));
                const selectedFastFilters = specificData.map((el) => ({
                    value: el.value,
                    label: el.label,
                    type: 'value',
                    inputType: (eqFilter === null || eqFilter === void 0 ? void 0 : eqFilter.inputType) || 'text',
                }));
                // Si ce filtre rapide est actuellement set dans les filtres généraux
                if (filters.some((filter) => filter[0].label === filterType)) {
                    const newFilters = [...filters.filter((el) => el[0].label !== filterType)];
                    if (selectedFastFilters[0].value === 'all') {
                        setFilters([...newFilters]);
                    }
                    else {
                        const filter = eqFilter
                            ? [columnFilter, eqFilter, ...selectedFastFilters]
                            : [columnFilter, ...selectedFastFilters];
                        setFilters([...newFilters, filter]);
                    }
                    // Si ce filtre rapide n'est pas set dans les filtres généraux
                }
                else if (selectedFastFilters.some((itemFilter) => itemFilter.value !== 'all')) {
                    const filter = eqFilter
                        ? [columnFilter, eqFilter, ...selectedFastFilters]
                        : [columnFilter, ...selectedFastFilters];
                    if (filters.length > 0) {
                        setFilters([...filters, filter]);
                    }
                    else {
                        setFilters([filter]);
                    }
                }
            }
        }
        return (_jsxs("div", { className: "fullHeight flex row noWrap alignCenter marginRight10 marginTop5", style: { position: 'relative', zIndex: 10, width: peopleSchemaId ? 900 : 300 }, children: [_jsx(Autocomplete, { selected: peopleSchemaId, options: map(peopleSchemas, (ps) => ({ label: ps.name, value: ps.id })), onChange: onChangeSchema, style: { marginRight: 5, height: 35, width: peopleSchemaId ? '40%' : '100%' }, animeKeyPressed: { shift: true } }), peopleSchemaId && (_jsxs(_Fragment, { children: [_jsx("div", { style: { width: '60%' }, children: _jsx(MUIAutocompleteSelect, { options: getOptions(projectPlans, 'projects'), label: "Projects", value: specificProject, style: { width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }, onChange: getOnChange(setSpecificProject, 'projects'), onClick: () => setFastFilters('Project', specificProject), multiple: true, titled: true, disabled: !peopleSchemaId }) }), _jsx("div", { style: { width: '60%' }, children: _jsx(MUIAutocompleteSelect, { options: getOptions(departments, 'departments'), label: "Departments", value: specificDepartment, style: { width: 200, marginRight: 10, position: 'absolute', top: -15, left: 0 }, onChange: getOnChange(setSpecificDepartment, 'departments'), onClick: () => setFastFilters('Department', specificDepartment), multiple: true, titled: true, disabled: !peopleSchemaId }) })] }))] }));
    };
    function ganttActions(instance, cell, depth) {
        const { updateTable } = instance;
        const { name, __resource, id } = cell.row.original;
        if (!canEditContracts)
            return [];
        const actions = [
            // People
            [],
            // contracts
            [
                {
                    label: 'Edit',
                    editAction: true,
                    rightLabel: 'Enter',
                    hotKeys: ['enter'],
                    onClick: (e) => {
                        editContract(e, instance, cell, cell.row.original);
                    },
                },
                {
                    label: 'Delete contract',
                    editAction: true,
                    rightLabel: 'Suppr',
                    hotKeys: ['suppr'],
                    onClick: (e) => {
                        confirmDelete({
                            render: `Are you sure you want to delete ${name} ?`,
                            onValidate: () => resources[__resource].delete(id).then(() => {
                                var _a;
                                const rowId = String(cell ? cell.row.id : 'root');
                                (_a = instance.updateOriginals) === null || _a === void 0 ? void 0 : _a.call(instance, { [rowId]: null }, 'delete');
                                updateTable();
                            }),
                        });
                    },
                },
            ],
        ];
        return actions[depth];
    }
    const resourcesParams = useMemo(() => {
        if (!dataLoaded)
            return null;
        return {
            resourceType: 'people',
            requestName: 'fetchAll',
            includedResources: [
                'contractsInst',
                'contractsInst.secureDataInst',
                'contractsInst.projectPlanInst',
                'contractsInst.projectPlanInst.projectInst',
                'attributesInst',
                'thumbnailInst',
            ],
            queries: {
                underContract: filterUnderContract || undefined,
            },
        };
    }, [dataLoaded, filterUnderContract]);
    const filters = useMemo(() => {
        if (dataLoaded)
            setFiltersLoaded(true);
        return new Filters({
            columns,
            personAttributes,
            externalJobs,
            projectPlans,
            offices,
            departments,
        }).getFilters();
    }, [columns, personAttributes, dataLoaded]);
    return (_jsx(Table, { setUserFilters: setUserFilters, filters: filters, tableId: tableId, exportExcelFormat: { assetId: '', data: { peopleSchema: peopleSchemaId }, type: 'peopleSchema' }, extendToolbar: extendToolbar, isLoading: !dataLoaded && !filtersLoaded, resourcesParams: resourcesParams, columns: columns, ganttActions: ganttActions, formatRowData: formatRowData, rowExpander: true, toggleButtons: toggleButtons, dropdownButton: dropdownButton, getPrefs: (prefs) => {
            tablePrefs.current = prefs;
        }, isGantt: true, showGantt: showGantt }));
};
