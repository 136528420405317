var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    fetchLoading: false,
    loading: false,
    data: [],
    error: null,
};
export const fetchAllDepartments = createAsyncThunk('departments/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departments.fetchAll({ page_size: 1000 });
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchOneDepartment = createAsyncThunk('departments/fetchOne', (id_1, _a) => __awaiter(void 0, [id_1, _a], void 0, function* (id, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departments.fetchOne(id);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createDepartment = createAsyncThunk('departments/create', (department_1, _a) => __awaiter(void 0, [department_1, _a], void 0, function* (department, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departments.create(department);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateDepartment = createAsyncThunk('departments/update', (department_1, _a) => __awaiter(void 0, [department_1, _a], void 0, function* (department, { fulfillWithValue, rejectWithValue }) {
    try {
        const response = yield api.departments.update(department);
        return fulfillWithValue(response);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteDepartment = createAsyncThunk('departments/delete', (id_1, _a) => __awaiter(void 0, [id_1, _a], void 0, function* (id, { fulfillWithValue, rejectWithValue }) {
    try {
        yield api.departments.delete(id);
        return fulfillWithValue(id);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { reducer } = createSlice({
    name: 'departmentsRedux',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // FETCH ALL
        builder.addCase(fetchAllDepartments.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllDepartments.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllDepartments.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // FETCH ONE
        builder.addCase(fetchOneDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchOneDepartment.fulfilled, (state, { payload }) => {
            const data = [...state.data];
            const newData = data.map((department) => {
                if (department.id === payload.id)
                    return payload;
                return department;
            });
            state.data = newData;
            state.fetchLoading = false;
        });
        builder.addCase(fetchOneDepartment.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // CREATE DEPARTMENT
        builder.addCase(createDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createDepartment.fulfilled, (state, { payload }) => {
            state.data = [...state.data, payload];
            state.loading = false;
        });
        builder.addCase(createDepartment.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
        // UPDATE DEPARTMENT
        builder.addCase(updateDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateDepartment.fulfilled, (state, { payload }) => {
            const data = [...state.data];
            const newData = data.map((department) => {
                if (department.id === payload.id)
                    return payload;
                return department;
            });
            state.data = newData;
            state.loading = false;
        });
        builder.addCase(updateDepartment.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
        // DELETE DEPARTMENT
        builder.addCase(deleteDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteDepartment.fulfilled, (state, { payload }) => {
            state.data = state.data.filter((department) => department.id !== payload);
            state.loading = false;
        });
        builder.addCase(deleteDepartment.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
    },
});
export default reducer;
