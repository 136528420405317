var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import resources from 'app/store/resources';
import Loader from 'app/components/Loader/Loader.jsx';
import { JobSkillLevelInput } from 'app/components/JobSkillLevelInput/JobSkillLevelInput.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectiveAgreementTitles } from 'app/store/reducers/collectiveAgreementTitles';
export function ModalEditJob(props) {
    var _a;
    const dispatch = useDispatch();
    const { loading: isLoading, data: collectiveAgreementTitles } = useSelector((state) => state.collectiveAgreementTitles);
    const { job, onSave, onChange } = props, modalProps = __rest(props, ["job", "onSave", "onChange"]);
    const [resourcesLoaded, setResourcesLoaded] = useState(false);
    const [skillAttributes, setSkillAttributes] = useState([]);
    useEffect(() => {
        Promise.all([
            dispatch(fetchCollectiveAgreementTitles()),
            resources.personAttributes
                .fetchAll({ params: { queries: { page_size: 1000, attrType: 'skill' } } })
                .then((res) => setSkillAttributes(res.resources)),
        ]).then(() => {
            setResourcesLoaded(true);
        });
    }, []);
    function saveSkillLevel(skillLevels, jobId) {
        return __awaiter(this, void 0, void 0, function* () {
            const toUpdate = [];
            const toCreate = [];
            const toDelete = [];
            skillLevels.forEach((sl) => {
                if (typeof sl === 'string')
                    toDelete.push(sl);
                else if (sl.id)
                    toUpdate.push(sl);
                else
                    toCreate.push(Object.assign(Object.assign({}, sl), { job: jobId }));
            });
            return Promise.all([
                toCreate.length > 0 ? resources.jobSkillLevels.create(toCreate) : undefined,
                toUpdate.length > 0 ? resources.jobSkillLevels.update(toUpdate) : undefined,
                toDelete.length > 0 ? resources.jobSkillLevels.delete(toDelete) : undefined,
            ]).then(([createdRes, updatedRes]) => {
                return [...((createdRes === null || createdRes === void 0 ? void 0 : createdRes.resources) || []), ...((updatedRes === null || updatedRes === void 0 ? void 0 : updatedRes.resources) || [])];
            });
        });
    }
    function save(data) {
        return __awaiter(this, void 0, void 0, function* () {
            // TO DO : THIS PART IS MAYBE TOO COMPLEX. CHECK IF REFACT IS POSSIBLE
            const { collectiveAgreementTitles: collectiveAgreementTitlesOptions, name, quoteReference, skillLevelsInst } = data;
            const collectiveAgreementTitles = collectiveAgreementTitlesOptions.map((opt) => opt.data);
            if (onSave)
                return onSave(Object.assign(Object.assign({}, job), { name, quoteReference }), collectiveAgreementTitles);
            if (job && name === job.name && quoteReference === job.quoteReference) {
                // TO DO : DON'T RESOLVE PROMISE BUT THROW ERROR
                Promise.resolve({ resources: [Object.assign(Object.assign({}, job), { name, quoteReference })] });
            }
            const res = yield resources.jobs[(job === null || job === void 0 ? void 0 : job.id) ? 'update' : 'create']({ name, id: job === null || job === void 0 ? void 0 : job.id, quoteReference });
            const resJob = res.resources[0];
            const oldJobCollectiveAgreementTitles = (job === null || job === void 0 ? void 0 : job.rel_jobCollectiveAgreementTitlesInst) || [];
            const toRemove = [];
            const toCreate = [];
            collectiveAgreementTitles.forEach((cat) => {
                const isExisting = oldJobCollectiveAgreementTitles.find((o) => o.collectiveAgreementTitle === cat.id && o.job === resJob.id);
                if (isExisting)
                    return;
                if (cat === null || cat === void 0 ? void 0 : cat.id)
                    toCreate.push({
                        collectiveAgreementTitle: cat.id,
                        job: resJob.id,
                    });
            });
            oldJobCollectiveAgreementTitles.forEach((ojcat) => {
                const isExisting = collectiveAgreementTitles.find((cat) => ojcat.collectiveAgreementTitle === cat.id && ojcat.job === resJob.id);
                if (isExisting)
                    return;
                toRemove.push(ojcat.id);
            });
            const [_, resCreated, skillLevelsInst2] = yield Promise.all([
                toRemove && toRemove.length > 0 && resources.jobCollectiveAgreementTitles.delete(toRemove, { batch: true }),
                toCreate && toCreate.length > 0 && resources.jobCollectiveAgreementTitles.create(toCreate, { batch: true }),
                saveSkillLevel(skillLevelsInst, resJob.id),
            ]);
            const rel_jobCollectiveAgreementTitlesInst = resCreated.resources || [];
            oldJobCollectiveAgreementTitles.forEach((ojcat) => {
                if (toRemove.includes(ojcat.id))
                    return;
                rel_jobCollectiveAgreementTitlesInst.push(ojcat);
            });
            onChange === null || onChange === void 0 ? void 0 : onChange(Object.assign(Object.assign({}, resJob), { collectiveAgreementTitles: collectiveAgreementTitles.map((cat) => cat.id), collectiveAgreementTitlesInst: collectiveAgreementTitles, rel_jobCollectiveAgreementTitlesInst,
                skillLevelsInst2, skillLevels: skillLevelsInst2.map((sl) => sl.id) }));
            return res;
        });
    }
    return (_jsx(ModalConfirmForm, Object.assign({ title: job ? `Edit job ${job.name || ''}` : 'Create a new job', draggable: true }, modalProps, { children: !resourcesLoaded ? (_jsx("div", { style: { height: 300 }, className: "fullWidth flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx(FormData, { onSave: onSave || save, flashNotifSuccessLabel: `Job ${job ? 'edited' : 'created'}`, defaultData: Object.assign(Object.assign({}, job), { skillLevelsInst: (job === null || job === void 0 ? void 0 : job.skillLevelsInst) || [], collectiveAgreementTitles: ((_a = job === null || job === void 0 ? void 0 : job.collectiveAgreementTitlesInst) === null || _a === void 0 ? void 0 : _a.map((cat) => ({
                    value: cat.id,
                    label: cat.jobTitle,
                    data: cat,
                }))) || [] }), properties: [
                {
                    label: 'Name',
                    key: 'name',
                    type: 'string',
                    elementProps: {
                        placeholder: 'Job title',
                        isRequired: true,
                    },
                },
                {
                    label: 'Collective agreement titles',
                    key: 'collectiveAgreementTitles',
                    type: 'muiautocomplete',
                    elementProps: (data, setData) => ({
                        dataCy: 'collectiveAgreementTitles',
                        value: data.collectiveAgreementTitles,
                        onChange: (option) => {
                            setData(Object.assign(Object.assign({}, data), { collectiveAgreementTitles: option }));
                        },
                        options: sortBy(collectiveAgreementTitles, ['jobTitle']).map((collectiveAgreement) => ({
                            label: collectiveAgreement.jobTitle,
                            value: collectiveAgreement.id,
                            data: collectiveAgreement,
                        })),
                        multiple: true,
                        isRequired: true,
                        style: { width: '100%' },
                    }),
                },
                {
                    label: 'Required skills',
                    key: 'skillLevelsInst',
                    type: 'custom',
                    element: (data, setData) => {
                        return (_jsx(JobSkillLevelInput, { jobSkillLevels: data.skillLevelsInst || [], onChange: (skillLevelsInst) => setData(Object.assign(Object.assign({}, data), { skillLevelsInst })), editable: true, style: { marginTop: '-28px' }, skillAttributes: skillAttributes }));
                    },
                },
                {
                    label: 'Quotation reference',
                    key: 'quoteReference',
                    type: 'string',
                },
            ] })) })));
}
