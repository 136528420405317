var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo } from 'react';
import Loader from 'app/components/Loader/Loader.jsx';
import { permission } from 'app/containers/Permissions/Permission.jsx';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { EditList } from '../EditList';
import { ModalEditCollectiveAgreementTitle } from './ModalEditCollectiveAgreementTitle';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCollectiveAgreementTitle, fetchCollectiveAgreementTitles, } from 'app/store/reducers/collectiveAgreementTitles';
export function CollectiveAgreementTitlesEditor() {
    const dispatch = useDispatch();
    const { loading: isLoading, data: collectiveAgreementTitles } = useSelector((state) => state.collectiveAgreementTitles);
    useEffect(() => {
        dispatch(fetchCollectiveAgreementTitles());
    }, []);
    useEffect(() => {
        documentTitle('Collective agreement titles', 'page');
    }, []);
    const onRemove = (id) => __awaiter(this, void 0, void 0, function* () {
        yield dispatch(deleteCollectiveAgreementTitle(id));
    });
    const canEdit = useMemo(() => permission(['human resources_settings_collective agreement titles_edit']), []);
    return isLoading ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter", children: _jsx(Loader, {}) })) : (_jsx("div", { className: "padding10", style: { width: '100%', height: '100%' }, children: _jsx(EditList, { title: "Collective Agreement Titles", unity: "Collective Agreement Title", titles: ['Title', 'Minimum Salary', 'Minimum salary 35h', 'Managerial', 'Field', 'Sector', 'Level', 'Mission'], labelSelectors: [
                'jobTitle',
                { key: 'minimumSalary', parser: (item) => Math.round(item.minimumSalary * 100) / 100 },
                { key: 'secondMinimumSalary', parser: (item) => Math.round(item.secondMinimumSalary * 100) / 100 },
                'managerial',
                'field',
                'sector',
                'level',
                'mission',
            ], useRedux: true, columnsSizes: [350, 150, 150, 150, 150, 100, 220, 140, 70], itemsList: collectiveAgreementTitles || {}, onRemove: (collectiveAgreementTitle) => onRemove(collectiveAgreementTitle === null || collectiveAgreementTitle === void 0 ? void 0 : collectiveAgreementTitle.id), readOnly: !canEdit, EditModal: (item) => _jsx(ModalEditCollectiveAgreementTitle, { collectiveAgreementTitle: item }) }) }));
}
